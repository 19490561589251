import { call, put, takeEvery } from "redux-saga/effects";
import { updateProfile, updateProfilePicture } from "../../../api/requests/auths";
import { updateProfileFailure, updateProfileSuccess, uploadPictureFailure, uploadPictureSuccess } from "./reducer";
import { toast } from "react-toastify";

function* profilePicture({ payload: user }) {
    const formData = new FormData();
    formData.append('image', user.file);

  const user_id = localStorage.getItem("user_id")

    try {
        const response = yield call(updateProfilePicture, formData, user_id)

        toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }) 

        yield put(uploadPictureSuccess(response));

    } catch (error) {
        console.log('register response', error)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

      yield put(uploadPictureFailure(error));
    }
  }

  //Update Profile
  function* updateUser({ payload: user }) {

    try {
        const response = yield call(updateProfile, user)

        toast.success(response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }) 

        yield put(updateProfileSuccess(response));

    } catch (error) {
        console.log('register response', error)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

      yield put(updateProfileFailure(error));
    }
  }


  function* profileSaga(){
    yield takeEvery('profile/uploadPicture', profilePicture)
    yield takeEvery('profile/updateProfile', updateUser)
  }

  export default profileSaga