import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: null,
  isLoading: false,
};

export const emailConfirmationSlice = createSlice({
  name: "emailConfirmation",
  initialState,
  reducers: {
    verifyEmail: (state) => {
        state.isLoading = true
    },
    verifyEmailSuccess: (state, action) => {
      state.response = action.payload.data;
      state.isLoading = false

    },

    verifyEmailFailure: (state) => {
        state.isLoading = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { verifyEmail, verifyEmailSuccess, verifyEmailFailure } =
emailConfirmationSlice.actions;

export const emailConfirmationSelector = (state) => state.emailConfirmation;

export default emailConfirmationSlice.reducer;
