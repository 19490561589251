import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import stoched from './../../assets/images/stoched_logo.png'

const Footer = () => {
  return (
    <div>
       <footer className="foot">
          <Container>
            <Row>
              <Col lg={12} sm={12}>
                <div style={{ textAlign: "center" }}>
                  <h5 className="mb-4 footer-list-title">
                    <img src={stoched} width="120" />
                  </h5>
                  <p>Copyright © 2023 Stoched. All right reserved</p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
    </div>
  )
}

export default Footer
