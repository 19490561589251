import React from 'react'

export const GetStartedIcon = ({ fill }) => {
  return (
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2ZM11.11 16.66C10.86 16.91 10.4 17.14 10.06 17.19L7.98 17.48C7.9 17.49 7.82 17.5 7.75 17.5C7.4 17.5 7.08 17.38 6.85 17.15C6.57 16.87 6.45 16.46 6.52 16.02L6.81 13.94C6.86 13.6 7.09 13.13 7.34 12.89L11.11 9.12C11.2434 9.49936 11.4142 9.86449 11.62 10.21C11.7 10.35 11.79 10.49 11.87 10.59C11.97 10.74 12.07 10.87 12.14 10.94C12.18 11 12.22 11.04 12.23 11.06C12.45 11.31 12.68 11.55 12.9 11.73C12.96 11.79 13 11.82 13.01 11.83C13.14 11.93 13.26 12.04 13.38 12.11C13.51 12.21 13.65 12.3 13.79 12.38C13.96 12.48 14.14 12.58 14.33 12.67C14.52 12.76 14.7 12.83 14.88 12.89L11.11 16.66ZM16.55 11.23L15.77 12.01C15.72 12.06 15.65 12.09 15.58 12.09C15.56 12.09 15.52 12.09 15.5 12.08C14.6493 11.8345 13.8748 11.3773 13.2487 10.7513C12.6227 10.1252 12.1655 9.35066 11.92 8.5C11.89 8.41 11.92 8.31 11.99 8.24L12.78 7.45C14.07 6.16 15.29 6.19 16.55 7.45C17.19 8.09 17.51 8.71 17.5 9.35C17.5 9.98 17.19 10.59 16.55 11.23Z" fill={fill}/>
        </svg>
    </>
  )
}

export const HomeIcon = (props) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z" fill="#696969"/>
      </svg>
    </>
  )
}

export const MoneyIcon = ({ fill }) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2519 8.07812V10.9381L10.2419 10.5881C9.73187 10.4081 9.42188 10.2381 9.42188 9.36812C9.42188 8.65812 9.95188 8.07812 10.6019 8.07812H11.2519ZM14.5799 14.6291C14.5799 15.3391 14.0499 15.9191 13.3999 15.9191H12.7499V13.0591L13.7599 13.4091C14.2699 13.5891 14.5799 13.7591 14.5799 14.6291V14.6291Z" fill={fill}/>
        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 14.1311 7.99902 13.9403 8.13967 13.7997C8.28032 13.659 8.47109 13.58 8.67 13.58C8.86891 13.58 9.05968 13.659 9.20033 13.7997C9.34098 13.9403 9.42 14.1311 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 9.86891 16.001 10.0597 15.8603 10.2003C15.7197 10.341 15.5289 10.42 15.33 10.42C15.1311 10.42 14.9403 10.341 14.7997 10.2003C14.659 10.0597 14.58 9.86891 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12V12Z" fill={fill}/>
      </svg>

    </>
  )
}

export const LeftArrow = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#03A48B"/>
        <path d="M14.5 17L9.5 12L14.5 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  )
}

export const PlusIcon = ({fill}) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" fill={fill}/>
      </svg>
    </>
  )
}

export const NotificationIcon = ({ fill }) => {
  return (
    <>
      <svg width="70" height="70" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.5865 18.5V23.5L26.8765 24.79C27.5065 25.42 27.0565 26.5 26.1665 26.5H12.9965C12.1065 26.5 11.6665 25.42 12.2965 24.79L13.5865 23.5V18.5C13.5865 15.42 15.2165 12.86 18.0865 12.18V11.5C18.0865 10.67 18.7565 10 19.5865 10C20.4165 10 21.0865 10.67 21.0865 11.5V12.18C23.9465 12.86 25.5865 15.43 25.5865 18.5ZM21.5865 27.5C21.5865 28.6 20.6865 29.5 19.5865 29.5C18.4765 29.5 17.5865 28.6 17.5865 27.5H21.5865Z" fill={fill}/>
        <circle cx="26" cy="14" r="3.5" fill="#FF453A" stroke="white"/>
      </svg>

    </>
  )
}

export const SettingsIcon = ({ fill }) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1 9.22091C18.29 9.22091 17.55 7.94091 18.45 6.37091C18.97 5.46091 18.66 4.30091 17.75 3.78091L16.02 2.79091C15.23 2.32091 14.21 2.60091 13.74 3.39091L13.63 3.58091C12.73 5.15091 11.25 5.15091 10.34 3.58091L10.23 3.39091C10.1222 3.20189 9.97789 3.0362 9.80545 2.90348C9.63302 2.77075 9.43591 2.67365 9.2256 2.61781C9.01529 2.56198 8.79597 2.54853 8.58041 2.57825C8.36486 2.60796 8.15736 2.68025 7.97 2.79091L6.24 3.78091C5.33 4.30091 5.02 5.47091 5.54 6.38091C6.45 7.94091 5.71 9.22091 3.9 9.22091C2.86 9.22091 2 10.0709 2 11.1209V12.8809C2 13.9209 2.85 14.7809 3.9 14.7809C5.71 14.7809 6.45 16.0609 5.54 17.6309C5.02 18.5409 5.33 19.7009 6.24 20.2209L7.97 21.2109C8.76 21.6809 9.78 21.4009 10.25 20.6109L10.36 20.4209C11.26 18.8509 12.74 18.8509 13.65 20.4209L13.76 20.6109C14.23 21.4009 15.25 21.6809 16.04 21.2109L17.77 20.2209C18.68 19.7009 18.99 18.5309 18.47 17.6309C17.56 16.0609 18.3 14.7809 20.11 14.7809C21.15 14.7809 22.01 13.9309 22.01 12.8809V11.1209C22.0048 10.6169 21.8015 10.1352 21.4442 9.77968C21.0868 9.42421 20.604 9.22351 20.1 9.22091ZM12 15.2509C10.21 15.2509 8.75 13.7909 8.75 12.0009C8.75 10.2109 10.21 8.75091 12 8.75091C13.79 8.75091 15.25 10.2109 15.25 12.0009C15.25 13.7909 13.79 15.2509 12 15.2509Z" fill={fill}/>
      </svg>
    </>
  )
}

export const VideoIcon = ({ fill }) => {
  return (
    <>
     <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xmlSpace="preserve">

        <defs>
        </defs>
        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
          <path d="M 45 0 C 20.147 0 0 20.147 0 45 c 0 24.853 20.147 45 45 45 s 45 -20.147 45 -45 C 90 20.147 69.853 0 45 0 z M 62.251 46.633 L 37.789 60.756 c -1.258 0.726 -2.829 -0.181 -2.829 -1.633 V 30.877 c 0 -1.452 1.572 -2.36 2.829 -1.634 l 24.461 14.123 C 63.508 44.092 63.508 45.907 62.251 46.633 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
        </g>
    </svg>
    </>
  )
}

export const ReportIcon = ({ fill }) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.11 16.9C9.11 17.18 8.89 17.4 8.61 17.4H5.82C5.54 17.4 5.32 17.18 5.32 16.9V12.28C5.32 11.65 5.83 11.14 6.46 11.14H8.61C8.89 11.14 9.11 11.36 9.11 11.64V16.9ZM13.89 16.9C13.89 17.18 13.67 17.4 13.39 17.4H10.6C10.32 17.4 10.1 17.18 10.1 16.9V7.74C10.1 7.11 10.61 6.6 11.24 6.6H12.76C13.39 6.6 13.9 7.11 13.9 7.74V16.9H13.89ZM18.68 16.9C18.68 17.18 18.46 17.4 18.18 17.4H15.39C15.11 17.4 14.89 17.18 14.89 16.9V13.35C14.89 13.07 15.11 12.85 15.39 12.85H17.54C18.17 12.85 18.68 13.36 18.68 13.99V16.9Z" fill={fill}/>
      </svg>
    </>
  )
}

export const LogoutIcon = () => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.16 14.8467H10.0733C7.11331 14.8467 5.68664 13.68 5.43998 11.0667C5.41331 10.7933 5.61331 10.5467 5.89331 10.52C6.15998 10.4933 6.41331 10.7 6.43998 10.9733C6.63331 13.0667 7.61998 13.8467 10.08 13.8467H10.1666C12.88 13.8467 13.84 12.8867 13.84 10.1733V5.82665C13.84 3.11332 12.88 2.15332 10.1666 2.15332H10.08C7.60664 2.15332 6.61998 2.94665 6.43998 5.07999C6.40664 5.35332 6.17331 5.55999 5.89331 5.53332C5.82761 5.52819 5.76356 5.51013 5.70485 5.48019C5.64614 5.45025 5.59392 5.40901 5.55118 5.35884C5.50844 5.30867 5.47603 5.25056 5.4558 5.18783C5.43558 5.12511 5.42793 5.05901 5.43331 4.99332C5.65998 2.33999 7.09331 1.15332 10.0733 1.15332H10.16C13.4333 1.15332 14.8333 2.55332 14.8333 5.82665V10.1733C14.8333 13.4467 13.4333 14.8467 10.16 14.8467Z" fill="#FF453A"/>
        <path d="M9.99975 8.5H2.41309C2.13975 8.5 1.91309 8.27333 1.91309 8C1.91309 7.72667 2.13975 7.5 2.41309 7.5H9.99975C10.2731 7.5 10.4998 7.72667 10.4998 8C10.4998 8.27333 10.2731 8.5 9.99975 8.5Z" fill="#FF453A"/>
        <path d="M3.89977 10.7333C3.7731 10.7333 3.64644 10.6866 3.54644 10.5866L1.3131 8.35331C1.22012 8.25922 1.16797 8.13226 1.16797 7.99998C1.16797 7.86769 1.22012 7.74074 1.3131 7.64664L3.54644 5.41331C3.73977 5.21998 4.05977 5.21998 4.2531 5.41331C4.44644 5.60664 4.44644 5.92664 4.2531 6.11998L2.3731 7.99998L4.2531 9.87998C4.44644 10.0733 4.44644 10.3933 4.2531 10.5866C4.15977 10.6866 4.02644 10.7333 3.89977 10.7333Z" fill="#FF453A"/>
      </svg>

    </>
  )
}

export const UserIcon = () => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.0001 8.50004C5.88677 8.50004 4.16677 6.78004 4.16677 4.66671C4.16677 2.55337 5.88677 0.833374 8.0001 0.833374C10.1134 0.833374 11.8334 2.55337 11.8334 4.66671C11.8334 6.78004 10.1134 8.50004 8.0001 8.50004ZM8.0001 1.83337C7.2492 1.83513 6.52955 2.13421 5.99858 2.66518C5.46761 3.19615 5.16853 3.9158 5.16677 4.66671C5.16853 5.41761 5.46761 6.13726 5.99858 6.66823C6.52955 7.1992 7.2492 7.49828 8.0001 7.50004C8.75101 7.49828 9.47066 7.1992 10.0016 6.66823C10.5326 6.13726 10.8317 5.41761 10.8334 4.66671C10.8317 3.9158 10.5326 3.19615 10.0016 2.66518C9.47066 2.13421 8.75101 1.83513 8.0001 1.83337V1.83337ZM13.7268 15.1667C13.4534 15.1667 13.2268 14.94 13.2268 14.6667C13.2268 12.3667 10.8801 10.5 8.0001 10.5C5.1201 10.5 2.77344 12.3667 2.77344 14.6667C2.77344 14.94 2.54677 15.1667 2.27344 15.1667C2.0001 15.1667 1.77344 14.94 1.77344 14.6667C1.77344 11.82 4.56677 9.50004 8.0001 9.50004C11.4334 9.50004 14.2268 11.82 14.2268 14.6667C14.2268 14.94 14.0001 15.1667 13.7268 15.1667Z" fill="#1D1D1F"/>
      </svg>
    </>
  )
}

export const EditIcon = () => {
  return (
    <>
      <svg width="14.64" height="16.67" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4794 0.651664L13.8544 1.74394C14.4182 2.18485 14.7941 2.76606 14.9227 3.37733C15.0711 4.04973 14.9128 4.71011 14.4677 5.2813L6.27706 15.8733C5.90116 16.3543 5.3472 16.6249 4.75368 16.6349L1.4893 16.675C1.31124 16.675 1.16286 16.5547 1.12329 16.3844L0.381387 13.1677C0.25279 12.5765 0.381387 11.9652 0.757286 11.4942L6.56393 3.97859C6.66285 3.85834 6.8409 3.8393 6.95961 3.92848L9.40295 5.87253C9.56122 6.0028 9.77885 6.07294 10.0064 6.04288C10.4911 5.98276 10.8175 5.54184 10.7681 5.07086C10.7384 4.83036 10.6197 4.62994 10.4614 4.47963C10.4119 4.43954 8.0873 2.57567 8.0873 2.57567C7.93892 2.45542 7.90925 2.23496 8.02795 2.08565L8.94791 0.892164C9.79863 -0.200108 11.2824 -0.300317 12.4794 0.651664Z" fill="#84868B"/>
      </svg>
    </>
  )
}

export const CameraEditIcon = () => {
  return (
    <>
      <svg width="19" height="19" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.56683 2C10.2135 2.00666 10.7402 2.33962 11.0468 2.93896C11.126 3.09711 11.2364 3.32811 11.352 3.57178L11.4916 3.86643L11.5602 4.0111L11.6268 4.1576C11.6535 4.20422 11.7002 4.23751 11.7602 4.23751C13.3602 4.23751 14.6668 5.54273 14.6668 7.14095V11.0966C14.6668 12.6948 13.3602 14 11.7602 14H4.24016C2.6335 14 1.3335 12.6948 1.3335 11.0966V7.14095C1.3335 5.54273 2.6335 4.23751 4.24016 4.23751C4.2935 4.23751 4.34683 4.21088 4.36683 4.1576L4.40683 4.07769C4.5935 3.68479 4.82016 3.20533 4.9535 2.93896C5.26016 2.33962 5.78016 2.00666 6.42683 2H9.56683ZM8.00016 6.26859C7.30016 6.26859 6.64016 6.54162 6.14016 7.04107C5.64683 7.54051 5.3735 8.19312 5.38016 8.88568C5.38016 9.58491 5.6535 10.2375 6.14683 10.737C6.64683 11.2297 7.30016 11.5028 8.00016 11.5028C8.72016 11.5028 9.3735 11.2098 9.84683 10.737C10.3202 10.2642 10.6135 9.61154 10.6202 8.88568C10.6202 8.19312 10.3468 7.53385 9.8535 7.03441C9.36016 6.54162 8.70016 6.26859 8.00016 6.26859ZM8.00016 7.26748C8.4335 7.26748 8.84016 7.43396 9.14683 7.74029C9.4535 8.04661 9.62016 8.45283 9.62016 8.88568C9.6135 9.77802 8.8935 10.5039 8.00016 10.5039C7.56683 10.5039 7.16016 10.3374 6.8535 10.0311C6.54683 9.72475 6.38016 9.31853 6.38016 8.88568V8.87902C6.3735 8.45949 6.54016 8.05327 6.84683 7.74695C7.16016 7.43396 7.56683 7.26748 8.00016 7.26748ZM11.7402 6.10877C11.4068 6.10877 11.1402 6.3818 11.1402 6.71476C11.1402 7.04772 11.4068 7.3141 11.7402 7.3141C12.0735 7.3141 12.3468 7.04772 12.3468 6.71476C12.3468 6.3818 12.0735 6.10877 11.7402 6.10877Z" fill="#696969"/>
      </svg>

    </>
  )
}


export const DownArrow = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9201 8.9502L13.4001 15.4702C12.6301 16.2402 11.3701 16.2402 10.6001 15.4702L4.08008 8.9502" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  )
}

export const WindowClose = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z" fill="#84868B"/>
    </svg>

  )
}

export const RightArrow = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3347_103900)">
      <path d="M7.5 3.75L13.75 10L7.5 16.25" stroke="#32ADE6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_3347_103900">
        <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)"/>
        </clipPath>
      </defs>
    </svg>

  )
}


