import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: null,
  isLoading: false,
};

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    passwordReset: (state) => {
        state.isLoading = true
    },
    changePassword: (state) => {
      state.isLoading = true
    },
    passwordResetSuccess: (state, action) => {
      state.response = action.payload.data;
      state.isLoading = false

    },

    passwordResetFailure: (state) => {
        state.isLoading = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { passwordReset, changePassword, passwordResetSuccess, passwordResetFailure } =
resetPasswordSlice.actions;

export const resetPasswordSelector = (state) => state.resetPassword;

export default resetPasswordSlice.reducer;
