import React, { useState } from 'react'
import Header from '../Landing/Header'
import { Container, Row, Col, Input, Label, FormFeedback } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from 'react-toastify';

import PhoneInput from "react-phone-input-2";
import StochedSelect from '../../components/CommonForBoth/Stoched/StochedSelect';
import { PulseLoader } from 'react-spinners';

const Feedback = () => {

  document.title = "Interest | Stoched";

    const [isSent, setIsSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const _handleSubmit = (values, { resetForm }) => {
    // console.log('submit',values);
    if (loading) return;
    setLoading(true);
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        "https://getform.io/f/0a8acd1d-73be-4bac-a8e5-5b77dc2d8752",
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        toast.success('Interest Submitted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }) 
        setLoading(false);

      })
      .catch(function (error) {
        toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        setLoading(false);
      });

        resetForm({ values: ''})

    };

    const ValidationSchema = Yup.object().shape({
        fullName: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        
    });

  return (
    <>
        <div>
            <Header/>
            <section
                className="hero-section hero-image"
                id="home"
                style={{ position: "relative" }}
            >
                <Container>
                    <div className="hero-wrapper mb-4 py-5">
                        <h4 className='text-center font-size-24'>Stoched Feedback</h4>
                    </div>
                </Container>
            </section>
            <section>
            <Row className="justify-content-center m-3">
                <Col xs={12} sm={12} lg={6} xl={4}>
                    <Formik
                        initialValues={
                            {
                                fullName: "",
                                email: "",
                                description: ""
                            }
                        }
                        onSubmit={_handleSubmit}
                        validationSchema={ValidationSchema}
                        >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                            <div className="mb-4 mt-2">
                                <Label className="form-label">Full Name</Label>
                                <Input
                                name="fullName"
                                type="text"
                                placeholder="Enter your full name"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName || ""}
                                invalid={
                                    props.touched.fullName && props.errors.fullName
                                    ? true
                                    : false
                                }
                                />
                                {props.touched.fullName && props.errors.fullName ? (
                                <FormFeedback type="invalid">
                                    {props.errors.fullName}
                                </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-4">
                                <Label className="form-label">Email</Label>
                                <Input
                                name="email"
                                type="text"
                                placeholder="Enter email address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.email || ""}
                                invalid={
                                    props.touched.email &&
                                    props.errors.email
                                    ? true
                                    : false
                                }
                                />
                                {props.touched.email &&
                                props.errors.email ? (
                                <FormFeedback type="invalid">
                                    {props.errors.email}
                                </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Comment </Label>
                                <textarea
                                name="description"
                                className="form-control"
                                rows="3"
                                placeholder="Enter your comment here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.description || ""}
                                />
                                <FormFeedback>{props.errors.description}</FormFeedback>
                            </div>

                            <p className="text-muted small">
                                To help us reach out to you when we go live or on our latest updates, please provide a valid email address.
                            </p>

                            <div className="mb-4 d-flex justify-content-end">
                                <button
                                className="btn btn-lg btn-primary px-4"
                                type="submit"
                                disabled={loading}
                                >
                                    {loading ? <PulseLoader color="#fff" size={7} /> : "Submit"}
                                
                                </button>
                            </div>
                            </form>
                        )}
                        </Formik>
                    </Col>
                </Row>

            </section>
        </div>
    </>

  )
}

export default Feedback
