import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  isLoading: false,
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    createRegister: (state) => {
        state.isLoading = true
    },
    registerSuccess: (state, action) => {
      state.token = action.payload.data.data;
      state.isLoading = false

    },

    registerFailure: (state) => {
        state.isLoading = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { createRegister, registerSuccess, registerFailure } =
  registerSlice.actions;

export const registerSelector = (state) => state.register;

export default registerSlice.reducer;
