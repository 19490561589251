let baseURL;

console.log('NODE_ENV:', process.env.NODE_ENV);
console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);

if (process.env.NODE_ENV === 'production'){
    console.log('Using production URL');
    baseURL = process.env.REACT_APP_API_BASE_URL;
} else if (process.env.NODE_ENV === 'staging'){
    console.log('Using staging URL');
    baseURL = process.env.REACT_APP_API_BASE_URL;
} else {
    console.log('Using development URL');
    baseURL = process.env.REACT_APP_API_BASE_URL;
}

console.log('baseURL:', baseURL);

export default baseURL;