import React from 'react'
import { useSelector } from 'react-redux';


const Note = () => {

  const { fetchedCourse } = useSelector(state => ({
    fetchedCourse: state.course.selected,

  }))

  return (
    <div className='mt-2'>
      <p className='font-size-20 mx-3'>{fetchedCourse?.description}</p>
    </div>
  )
}

export default Note
