import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row,  Nav,
    NavItem,
    NavLink, TabContent,
    TabPane, Collapse } from "reactstrap";
import classnames from "classnames";
import Note from './Note';
import Resources from './Resources';
import Quiz from './Quiz';
import Task from './Task';
import Tool from './Tool';
import Schedule from './Schedule';
import Certificate from './Certificate';

import { useSelector, useDispatch } from 'react-redux';
import VideoPlayer from '../../components/CommonForBoth/Stoched/VideoPlayer';
import {  getQuiz, coursesEnrolled, selectVideo, markChapter, getQuizScore, getCourse, fetchCourse, getResources, setTitle, removeTitle } from '../../store/course/reducer';
import { Oval } from  'react-loader-spinner'

import { MdVideoLibrary } from 'react-icons/md';

const Course = () => {
    const dispatch = useDispatch();

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const [activeAccordion, setActiveAccordion] = useState(1);
    const [selectedChapterVideo, setSelectedChapterVideo] = useState(null);
    const [selectedChapterIndex, setSelectedChapterIndex] = useState(-1);
    const [chapterCompletion, setChapterCompletion] = useState([]);

    const [activeSectionId, setActiveSectionId] = useState(null);
    const [activeChapterId, setActiveChapterId] = useState(null);

    const [ content, setContent ] = useState([])    

    let { courseId } = useParams();

    const { url, filteredEnrolled, loading, selected, response  } = useSelector(state => ({
        url: state.course.selectedVideo,
        filteredEnrolled: state.course.filteredEnrolled,
        loading: state.course.isLoading,
        selected: state.course.selected,
        response: state.course.response,
    }))

    console.log('Selected', selected)

    useEffect(() => {
		dispatch(getQuizScore(courseId))
	}, [response])


  useEffect(() => {
    dispatch(getQuiz(courseId))
    dispatch(getQuizScore(courseId))
    dispatch(coursesEnrolled())
    dispatch(getCourse(courseId))
    dispatch(getResources(courseId))
    // dispatch(filterEnrolledCoursesById(courseId))
    dispatch(removeTitle())
  }, [])

  useEffect(() => {
    setContent(selected?.sections)
    dispatch(setTitle(selected?.title))
  }, [selected])

  useEffect(() => {
    dispatch(selectVideo(selectedChapterVideo))
  }, [selectedChapterVideo])

  const toggleAccordion = (index) => {
    setActiveAccordion(prevIndex => (prevIndex === index ? -1 : index));
  };

//   const toggleHeader = (tab) => {
//     if (headerTab !== tab) setHeaderTab(tab);
//   };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const handleChapterSelect = (item, index, chapter) => {

    setSelectedChapterIndex(index);
    setSelectedChapterVideo(chapter.video);

    setActiveSectionId(item._id);
    setActiveChapterId(chapter._id);

    // Mark the selected chapter as not completed initially
    const newChapterCompletion = [...chapterCompletion];
    newChapterCompletion[index] = false;
    setChapterCompletion(newChapterCompletion);
  };

    // Step 2: Pass a callback to VideoPlayer to notify chapter completion
    const handleVideoComplete = () => {

        const newChapterCompletion = [...chapterCompletion];
        newChapterCompletion[selectedChapterIndex] = true;
        setChapterCompletion(newChapterCompletion);

        const payload = {
            'courseId': courseId,
            'sectionId': activeSectionId,
            'chapterId': activeChapterId,
            'status': true,
        }

        dispatch(markChapter(payload))
        dispatch(coursesEnrolled())
        dispatch(fetchCourse(courseId))

        if (
            selectedChapterIndex < content.length - 1 &&
            chapterCompletion[selectedChapterIndex + 1]
        ) {
            handleChapterSelect(item, selectedChapterIndex + 1, content[selectedChapterIndex + 1].chapters[0]);
        }
    };


  return (
    <div className="page-content">
        <Container fluid>
            {loading ? <div className='d-flex justify-content-center mt-5'><Oval
                height={50}
                width={50}
                color="#5156be"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                ariaLabel='oval-loading'
                secondaryColor="#5156be"
                strokeWidth={5}
                strokeWidthSecondary={2}

                /></div> : 
                    // selected.length !== 0 ?
                    <Row>
                    <Col xs={9}>
                        {selected.length !== 0 &&  <div className="card-deck-wrapper">
                            <div className="card-group">
                                 <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                                    <VideoPlayer videoKey={url} onVideoComplete={handleVideoComplete} />
                                </div>
                            </div>
                        </div> }
                       
                        {selected.length !== 0 && <div className='mt-4'> 
                            <Nav tabs className="nav-tabs-custom nav-justified px-0">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                        toggleCustom("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-15">Description</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                        toggleCustom("2");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-15">Resources</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                        toggleCustom("3");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="far fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-15">Quiz</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "4",
                                        })}
                                        onClick={() => {
                                        toggleCustom("4");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-15">Task</span>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "5",
                                        })}
                                        onClick={() => {
                                        toggleCustom("5");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Tool</span>
                                    </NavLink>
                                </NavItem> */}
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "6",
                                        })}
                                        onClick={() => {
                                        toggleCustom("6");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-15">Schedule</span>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                        active: customActiveTab === "7",
                                        })}
                                        onClick={() => {
                                        toggleCustom("7");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                        <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-15">Certificate</span>
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
    
                            <TabContent
                                activeTab={customActiveTab}
                                className="p-3 text-muted px-0"
                            >
                                <TabPane tabId="1">
                                <Note/>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Resources/>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Quiz/>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Task/>
                                </TabPane>
                                {/* <TabPane tabId="5">
                                    <Tool/>
                                </TabPane> */}
                                <TabPane tabId="6">
                                    <Schedule/>
                                </TabPane>
                                {/* <TabPane tabId="7">
                                    <Certificate/>
                                </TabPane> */}
                      </TabContent>
                        </div>}
                    </Col>
                    <Col xs={3}>
                       {selected.length !== 0 &&
                        <div className="card-deck-wrapper">
                            <div className="card-group">
                                <Card className="mb-4">
                                    <CardBody className='px-0'>
                                        <div className='d-flex justify-content-between'>
                                            <h4 className="px-3 font-size-20">Content</h4>
                                            <h4 className="px-3">{selected?.completion}%</h4>
                                        </div>
                                        {/* <Content/> */}
                                        <div>
                                            {content?.map((item, index) => (
                                            <div
                                                key={index}
                                                className="accordion accordion-flush"
                                                id={`accordionFlushExample${index}`}
                                                
                                            >
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={`headingFlushOne${index}`}>
                                                        <button
                                                        className={classnames(
                                                            "accordion-button",
                                                            "fw-medium",
                                                            {
                                                            collapsed: activeAccordion !== index + 1,
                                                            }
                                                        )}
                                                        type="button"
                                                        onClick={() => {
                                                            toggleAccordion(index + 1);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                        >
                                                        <span className='font-size-15'>{item?.title}</span>
                                                        </button>
                                                    </h2>
    
                                                <Collapse isOpen={activeAccordion === index + 1} className="accordion-collapse">
                                                    <div className="accordion-body">
                                                    {item.chapters?.map((chapter, chapterIndex) => (
                                                        <div key={chapterIndex} className="form-check mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`formCheck${chapterIndex}`}
                                                            defaultChecked={chapter.completed}
                                                            // defaultChecked={chapterCompletion[chapterIndex]}
                                                            onChange={() => handleChapterSelect(item, chapterIndex, chapter)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`formCheck${chapterIndex}`}>
                                                            <span className="d-block">{chapterIndex + 1}. {chapter?.description}</span>
                                                            <span className="d-flex">
                                                            <span className="me-2"><MdVideoLibrary /></span>
                                                            <p className="card-text"><small className="text-muted">{`${chapter.duration?.hour} : ${chapter.duration?.minute} : ${chapter.duration?.seconds}`}</small></p>
                                                            </span>
                                                        </label>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </Collapse>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        }
                    </Col>
                </Row>
            }

           


            
        </Container>
    </div>
  )
}

export default Course
