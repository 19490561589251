import React, { useEffect, useRef, useState } from 'react';

const VideoPlayer = ({ videoKey, onVideoComplete }) => {
  const videoRef = useRef(null);
  const [url, setUrl] = useState(videoKey);

  console.log('Video URL', url)

  useEffect(() => {
    setUrl(videoKey);
  }, [videoKey]);

  const handleVideoComplete = () => {
    if (onVideoComplete) {
      onVideoComplete();
    }
  };

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (video) {
      // Video metadata is loaded, you can perform any additional setup here
      // console.log(`The video is ${video.duration} seconds long.`);
    }
  };

  return (
    <div>
      <video
        ref={videoRef}
        src={url}
        controls={true}
        autoPlay
        className="embed-responsive-item"
        controlsList="nodownload"
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={handleVideoComplete}
      />
    </div>
  );
};

export default VideoPlayer;
