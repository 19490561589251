import React, { useState } from 'react'
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import resource1 from "../../assets/images/cert.png"
// import { Empty, Icon } from 'uiw';
import Empty from '@uiw/react-empty';

import downloadIcon from "../../assets/icons/stoched/download.svg";
import { useImageViewer } from "react-image-viewer-hook";

const IMAGES = [ resource1 ];

const Certificate = () => {
    const { getOnClick, ImageViewer } = useImageViewer();
    const [ isApproved, setIsApproved ] = useState(true)

  return (
    <div className='my-4'>
        <Row>

            {isApproved? (
                <Col lg={6}>
                <Card>
                   
                    <Row className="align-items-center">
                        <Col md={4}>
                            {IMAGES.map((src) => (
                            <a
                                key={src}
                                href={`${src}?auto=compress&cs=tinysrgb&w=1200`}
                                onClick={getOnClick(`${src}?auto=compress&cs=tinysrgb&w=1200`)}
                            >
                                {/* <img className="card-img img-fluid" src={resource4} alt="" /> */}

                                <img className="card-img img-fluid" src={`${src}?auto=compress&cs=tinysrgb&w=300`} />
                            </a>
                            ))}
                        </Col>
                        <Col md={8}>
                            <CardBody>
                                <h5 className="card-title">Certified Stoched</h5>
                                <p className="card-text"><small className="text-muted">Issued 3 mins ago</small></p>
                                <div className='d-flex justify-content-between'>
                                    {IMAGES.map((src) => (
                                        <a
                                            key={src}
                                            href={`${src}?auto=compress&cs=tinysrgb&w=1200`}
                                            onClick={getOnClick(`${src}?auto=compress&cs=tinysrgb&w=1200`)}
                                            className='mt-2'
                                        >
                                            <Link to="#" className="card-link me-3">View</Link>

                                        </a>
                                        ))}
                                    <div>
                                        <Button
                                        color="outline-light"
                                        className="bg-transparent d-flex align-items-center"
                                        >
                                        <img src={downloadIcon} alt="" className="me-2" />
                                            Download
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </Col>
            ) : (
                <Col md={12}>
                {/* <Empty
                description={
                    <span>
                    Customize <a href="#">Description</a>
                    </span>
                }
                    icon={<Icon type="shopping-cart"  style={{ fontSize: '39px' }} />}
                ></Empty> */}
                <Empty description={ <span> No certificate issued </span>} />
            </Col>
            )}
            
        </Row>
        <ImageViewer />
    </div>
  )
}

export default Certificate
