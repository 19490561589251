import { call, put, takeEvery } from "redux-saga/effects";
import { forgotPassword } from "../../../api/requests/auths";
import { passwordForgetFailure, passwordForgetSuccess } from "./reducer";
import { toast } from "react-toastify";

function* forgetPass({ payload: values }) {

    console.log('forgot', values)

    try {
        const response = yield call(forgotPassword, values)

        console.log('forgot pass', response)

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(passwordForgetSuccess(response));

    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(passwordForgetFailure(error));
    
    }
  }

  function* forgetPassSaga(){
    yield takeEvery('forgotPassword/passwordForget', forgetPass)
  }

  export default forgetPassSaga