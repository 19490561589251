import { TOKEN, ENDPOINTS } from "../constants";
import axios from "axios";
// import { store } from "../../store";
import baseUrl from "../../api";


const options = {
  headers: {
    Authorization: "Bearer " + TOKEN,
  },
};


// const baseUrl = process.env.REACT_APP_API_BASE;

//Register
export function register(values) {
  console.log('values', values)
  let url = `${baseUrl}` + ENDPOINTS.register;
  return axios.post(url, values);
}

//Login User
export function signInUser(values) {
    // console.log('login payload', values)
    
  let url = `${baseUrl}` + ENDPOINTS.login;
  return axios.post(url, values);
}

//Confirm Email
export function emailConfirm(values){
  let url = `${baseUrl}` + ENDPOINTS.confirmEmail + `${values}`;
  return axios.put(url);
}

//Logout
export function logout() {
  let url = `${baseUrl}` + ENDPOINTS.logout;
  return axios.post(url, {}, options);
}

//Confirm Email
export function forgotPassword(values){
  let url = `${baseUrl}` + ENDPOINTS.forgotPassword;
  return axios.put(url, values);
}

//Reset Password
export function resetPassword(values){
  console.log('values', values);
  let url = `${baseUrl}` + ENDPOINTS.resetPassword + `${values.token}`;
  return axios.put(url, {'newPassword': values.newPassword} );
}

//Change Password
export function changePassword(values){
  let url = `${baseUrl}` + ENDPOINTS.changePassword + `${TOKEN}`;
  return axios.put(url, values  );
}

//Update Profile
export function updateProfile(values){
  console.log('update profile', values)

  const payload = {
    'firstName': values.firstName,
    'lastName': values.lastName,
    'email': values.email,
    'mobile': values.mobile, 
    'address': values.address,
    'profession': values.profession,
    'interest': values.interest,
    'howYouHeardAboutUs': values.howYouheardAboutUs,
  }

  let url = `${baseUrl}` + ENDPOINTS.updateProfile + `${values.userId}`;
  return axios.put(url, payload, options);
}

//Get Logged In User
export function getUser() {
  let url = `${baseUrl}` + ENDPOINTS.loggedInUser;
  return axios.get(url, options);
}

//Update Profile Picture
export function updateProfilePicture(formData, user_id){
  console.log('profile values', formData)
  console.log('user id', user_id)
  let url = `${baseUrl}` + ENDPOINTS.updatePicture + `${user_id}`;
  return axios.put(url, formData);
}

//Generate Refresh Token
export function refreshToken(values) {
  let url = `${baseUrl}` + ENDPOINTS.refreshToken;
  return axios.post(url, values, options);
}