import { TOKEN, ENDPOINTS } from "../constants";
import axios from "axios";
// import { store } from "../../store";
import baseUrl from "../../api";

const options = {
  headers: {
    Authorization: "Bearer " + TOKEN,
  },
};

// const baseUrl = process.env.REACT_APP_API_BASE;


//Fetch Courses
export function fetchCourses(query) {
  console.log('course query', query)
  let url = `${baseUrl}` + ENDPOINTS.fetchCourses + "?" + `page=${query.pageNumber}&search=${query.searchTerm}`;
  return axios.get(url, options);
}

//Fetch A Course
export function fetchCourse(course_id) {
  let url = `${baseUrl}` + ENDPOINTS.fetchCourse + `${course_id}`;
  return axios.get(url, options);
}

//Fetch Quiz
export function fetchQuiz(course_id) {
  let url = `${baseUrl}` + ENDPOINTS.fetchQuiz + `${course_id}`;
  return axios.get(url, options);
}

//Fetch Quiz Score
export function fetchQuizScore(course_id) {
  let url = `${baseUrl}` + ENDPOINTS.fetchQuizScore + `${course_id}`;
  return axios.get(url, options);
}


//Enrol Course
export function enrolCourse(course_id) {
  let url = `${baseUrl}` + ENDPOINTS.enrolCourse + `${course_id}`;
  return axios.post(url, {}, options);
}

//Record Quiz
export function recordQuiz(query) {
  let url = `${baseUrl}` + ENDPOINTS.recordQuiz + `${query.courseId}`;
  return axios.put(url, {
      "quizScore": query.score
  }, options);
}

//Get Students Enrolled Courses
export function enrolledCourses(query) {
  let url = `${baseUrl}` + ENDPOINTS.enrolledCourses + "?" + `page=${query.pageNumber}&search=${query.searchTerm}`;
  return axios.get(url, options);
}

//Mark Chapter Video as Complete
export function chapterComplete(query) {
  let url = `${baseUrl}` + ENDPOINTS.chapterComplete + `${query.courseId}` + "/" + `${query.sectionId}` + "/" + `${query.chapterId}` + "/" + `${query.status}`;
  return axios.put(url, {}, options);
}

//Fetch Course Resources
export function fetchResources(query) {
  let url = `${baseUrl}` + ENDPOINTS.fetchResources + `${query.courseId}`;
  return axios.get(url, options);
}