import React, {useState} from 'react'
import Dropzone from "react-dropzone";
import { Col, Row, Card, Button } from "reactstrap";
import { Link } from "react-router-dom";
import downloadIcon from "../../assets/icons/stoched/download.svg"
import { useSelector, useDispatch } from 'react-redux';
import { ENDPOINTS, TOKEN } from '../../api/constants';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { toast } from "react-toastify";
import Empty from '../../components/CommonForBoth/Stoched/Empty';
import OrdersBagImg from "../../assets/images/orderbags.svg"
import baseUrl from '../../api';

// const baseUrl = process.env.REACT_APP_API_BASE;

const options = {
  headers: {
    Authorization: "Bearer " + TOKEN,
  },
};

const Task = () => {
  const dispatch = useDispatch();

  const [selectedFiles, setselectedFiles] = useState([]);
  const [loading, setLoading] = useState(false)

  const { fetchedCourse } = useSelector(state => ({
    fetchedCourse: state.course.selected,
  }))

  const hasProject = !fetchedCourse.hasProject


  let url = `${baseUrl}` + ENDPOINTS.downloadProject + `${fetchedCourse?._id}`;

  
  let submitUrl = `${baseUrl}` + ENDPOINTS.submitProject + `${fetchedCourse?._id}`;


const handleSubmit = async() => {

  var formData = new FormData();
  formData.append('spreadsheet', selectedFiles[0])

  try {
    setLoading(true)
    // Make a POST request to your API endpoint
    const response = await axios.post(submitUrl, formData, options);
    setLoading(false)

    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      }) 

  } catch (error) {
    // console.error('Error fetching or downloading the file:', error);
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    setLoading(false)

  }
  

}

  const handleDownload = async () => {
    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(url, {
        // Add any request data here if needed
      });
    
      const data = response.data.data;

      if (data) {
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = data;
        link.download = 'project-spreadsheet'; // Set the desired file name here
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up the temporary anchor element
        document.body.removeChild(link);
      } else {
        console.log('Download link not available.');
      }
    } catch (error) {
      console.error('Error fetching or downloading the file:', error);
    }
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
   function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // remove attachment
  function removeFile(f) {
    const newFiles = selectedFiles.filter((file) => file !== f);
    setselectedFiles(newFiles);
  }

  return (
    <div className='mx-3'>

      {!hasProject ? (
        <div>
          <h5>Instruction</h5>
          <div className="">
          <div>
              <p className="pe-4 font-size-16">After completing my program, I plan on working in industry or research as a professor to continue collaborating with other individuals in this field as well as mentoring students.</p>
          </div>
        </div>
        <div className='my-3'>
            <Button
              color="outline-light"
              className="bg-transparent d-flex align-items-center mt-3"
              onClick={handleDownload}
              disabled={hasProject}
            >
              <img src={downloadIcon} alt="" className="me-2" />
              Download
            </Button>
        </div>
      <div>
        <Row>
            <Col xl={5}>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                  // setFieldValue('files', acceptedFiles)
                }}
                accept="image/*, application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone mt-1">
                    <div
                      className="dz-message needsclick"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-0">
                        <i className="display-5 text-muted bx bx-cloud-upload" />
                      </div>
                      <h5>Drop or click to browse.</h5>
                      
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews my-5" id="file-previews">
                {selectedFiles?.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            {f.type === "application/pdf" ? (
                              <div className="avatar-sm">
                                <div className="avatar-title rounded bg-light text-muted font-size-24">
                                  <i className="fas fa-file-pdf" />
                                </div>
                              </div>
                            ) : (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            )}
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted document-upload-title"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0 font-size-12">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                          {/* delete icon */}
                          <Col className="col-auto">
                            <Link
                              to="#"
                              className="btn btn-link p-1"
                              onClick={() => {
                                removeFile(f);
                              }}
                            >
                              <i className="dripicons-cross text-danger fs-5" />
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="mb-4">
              <button
                className="btn btn-lg btn-primary px-4"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <PulseLoader color="#fff" size={7} /> : "Submit Task"}
              </button>
            </div>
      </div>
        </div>
      ) : (
        <div className='my-2'>
          <Empty
            img={OrdersBagImg}
              title="No Project Attached"
              msg="Author doesn't have project for this course"
            />
        </div>
               
      )
      
      }
          
    </div>
  )
}

export default Task
