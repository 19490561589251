import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: null,
  isLoading: false,
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    passwordForget: (state) => {
        state.isLoading = true
    },
    passwordForgetSuccess: (state, action) => {
      state.response = action.payload.data;
      state.isLoading = false

    },
    passwordForgetFailure: (state) => {
        state.isLoading = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { passwordForget, passwordForgetSuccess, passwordForgetFailure } =
forgotPasswordSlice.actions;

export const forgotPasswordSelector = (state) => state.forgotPassword;

export default forgotPasswordSlice.reducer;
