import React from 'react'
import { Link } from 'react-router-dom';

const Schedule = () => {
  return (
    <div className='text-center mt-5'>
      <Link to="#" className="card-link me-3 font-size-20">Calendly</Link>
      <p className='font-size-15 mt-2 mb-1'>Send all enquiries or complaints to:</p>
      <h4 className='text-muted'>info@stoched.com</h4>
    </div>
  )
}

export default Schedule
