import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: [],
  active: null,
  isLoading: false,
  isRefreshing: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    createLogin: (state) => {
        state.isLoading = true
    },
    loginSuccess: (state, action) => {
      state.active = action.payload.data;
      state.isLoading = false
    },

    loginFailure: (state) => {
        state.isLoading = false
    },

    getUser: (state) => {
      state.isLoading = true
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false
    },

    getUserFailure: (state) => {
        state.isLoading = false
    },

    //Refresh Token
    createRefreshToken: (state) => {
      state.isRefreshing = true
    },
    createRefreshTokenSuccess: (state, action) => {
      state.user = action.payload;
      state.isRefreshing = false
    },
    createRefreshTokenFailure: (state, action) => {
        state.error = action.payload
        state.isRefreshing = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  getUser, getUserSuccess, getUserFailure, 
  createLogin, loginSuccess, loginFailure,
  createRefreshToken, createRefreshTokenSuccess, createRefreshTokenFailure

  } =
  loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;
