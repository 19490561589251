import React from 'react'
import Footer from '../Landing/Footer'
import Header from '../Landing/Header'
import CertificateBody from './CertificateBody'

const Certificate = () => {
  document.title = "Certificate | Stoched";

  return (
    <div>
        <Header/>
        <CertificateBody/>
        <Footer/>
    </div>
  )
}

export default Certificate
