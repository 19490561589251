import { call, put, takeEvery } from "redux-saga/effects";
import { logout, signInUser } from "../../../api/requests/auths";
import { logoutFailure, logoutSuccess } from "./reducer";
import { toast } from "react-toastify";

function* logOutUser() {

    try {
        const response = yield call(logout)
        localStorage.removeItem("token")

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(logoutSuccess(response));
        window.location.href = "/login"
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(logoutFailure(error));

    }
  }

  function* logOutSaga(){
    yield takeEvery('logout/logoutUser', logOutUser)
  }

  export default logOutSaga