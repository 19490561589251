import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from "reactstrap";
import { useSelector } from 'react-redux';
import { ENDPOINTS, TOKEN } from '../../api/constants';
import axios from 'axios';
import Empty from '../../components/CommonForBoth/Stoched/Empty';
import OrdersBagImg from "../../assets/images/orderbags.svg"
import baseUrl from '../../api';

// const baseUrl = process.env.REACT_APP_API_BASE;

const options = {
    headers: {
      Authorization: "Bearer " + TOKEN,
    },
};

const Resources = () => {

  const { resources } = useSelector((state) => ({
    resources: state.course.resources,
}));

const handleDownload = async (documentKey) => {

    let url = `${baseUrl}` + ENDPOINTS.downloadResource + `${documentKey}`;
    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(url);

      const data = response.data.data;

      if (data) {    
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = data;
        link.download = 'project-spreadsheet.pdf'; // Set the desired file name here
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up the temporary anchor element
        document.body.removeChild(link);
      } else {
        console.log('Download link not available.');
      }
    } catch (error) {
      console.error('Error fetching or downloading the file:', error);
    }
  }


  return (
    <div className='my-4 mx-3'>
        <Row>
            {resources.documents?.map((item, i) => 
                <Col md={6} xl={4} key={i}>
                    <Card className="p-2">
                        <div className='d-flex justify-content-between'>
                            <div>{item?.title}</div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-success btn-sm ms-2"
                                    onClick={() => handleDownload(item?.documentKey)}
                                >
                                    Download
                                </button>
                            </div>
                        </div>
                    </Card>
                </Col>
            )}

            {resources?.length === 0 && (
                 <div className='text-center font-size-20'>
                    <Empty
                        img={OrdersBagImg}
                        title="No Resources Available"
                        msg="The author does not provide resource for this course"
                    />
             </div>
            )}
        </Row>
    </div>
  )
}

export default Resources
