import React, { useState } from 'react'
import Header from '../Landing/Header'
import { Container, Row, Col, Input, Label, FormFeedback } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from 'react-toastify';

import PhoneInput from "react-phone-input-2";
import StochedSelect from '../../components/CommonForBoth/Stoched/StochedSelect';
import { PulseLoader } from 'react-spinners';

const Interest = () => {

  document.title = "Interest | Stoched";

    const [isSent, setIsSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const message = "Hello status modal";

    const phoneRegex = RegExp(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    );

    const professions = [
        {'id': 1, 'name': 'Student'},
        {'id': 2, 'name': 'Professional'},
        {'id': 3, 'name': 'Others'},
    ]

    const interests = [
        {'id': 1, 'name': 'Engineering'},
        {'id': 2, 'name': 'Design'},
        {'id': 3, 'name': 'Marketing'},
        {'id': 4, 'name': 'Finance'},
        {'id': 5, 'name': 'Research'},
        {'id': 6, 'name': 'Development'},
        {'id': 7, 'name': 'Others'},
    ]

    const socials = [
        {'id': 1, 'name': 'Linkedin'},
        {'id': 2, 'name': 'Google'},
        {'id': 3, 'name': 'Facebook'},
        {'id': 4, 'name': 'Word of Mouth'},
        {'id': 5, 'name': 'Others'},
    ]

    const _handleSubmit = (values, { resetForm }) => {
    // console.log('submit',values);
    if (loading) return;
    setLoading(true);
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        "https://getform.io/f/0a8acd1d-73be-4bac-a8e5-5b77dc2d8752",
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        toast.success('Interest Submitted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }) 
        setLoading(false);

      })
      .catch(function (error) {
        toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        setLoading(false);
      });

        resetForm({ values: ''})

    };

    const ValidationSchema = Yup.object().shape({
        fullName: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        
    });


  return (
    <>
        <div>
            <Header/>
            <section
                className="hero-section hero-image"
                id="home"
                style={{ position: "relative" }}
            >
                <Container>
                    <div className="hero-wrapper mb-4 py-5">
                        <h4 className='text-center font-size-24'>Expression of Interest</h4>
                    </div>
                </Container>
            </section>
            <section>
            <Row className="justify-content-center m-3">
                <Col xs={12} sm={12} lg={6} xl={4}>
                    <Formik
                        initialValues={
                            {
                                fullName: "",
                                email: "",
                                mobile: "",
                                profession: "",
                                interest: "",
                                link: "",
                                description: ""
                            }
                        }
                        onSubmit={_handleSubmit}
                        validationSchema={ValidationSchema}
                        >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                            <div className="mb-4 mt-2">
                                <Label className="form-label">Full Name</Label>
                                <Input
                                name="fullName"
                                type="text"
                                placeholder="Enter your full name"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName || ""}
                                invalid={
                                    props.touched.fullName && props.errors.fullName
                                    ? true
                                    : false
                                }
                                />
                                {props.touched.fullName && props.errors.fullName ? (
                                <FormFeedback type="invalid">
                                    {props.errors.fullName}
                                </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-4">
                                <Label className="form-label">Email</Label>
                                <Input
                                name="email"
                                type="text"
                                placeholder="Enter email address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.email || ""}
                                invalid={
                                    props.touched.email &&
                                    props.errors.email
                                    ? true
                                    : false
                                }
                                />
                                {props.touched.email &&
                                props.errors.email ? (
                                <FormFeedback type="invalid">
                                    {props.errors.email}
                                </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-4">
                                <Label className="form-label">Mobile Number</Label>
                                        <PhoneInput
                                            name="mobile"
                                            country={"gh"}
                                            onlyCountries={["ke", "ng", "gh"]}
                                            value={props.values.mobile}
                                            inputClass={
                                                props.touched.mobile &&
                                                props.errors.mobile &&
                                                "is-invalid"
                                            }
                                            onBlur={() => props.setFieldTouched("mobile", true)}
                                            onChange={(value) => props.setFieldValue("mobile", value)}
                                            invalid={
                                                props.touched.mobile && props.errors.mobile ? true : false
                                            }
                                        />
                                        {props.touched.mobile && props.errors.mobile && (
                                        <p className="text-danger phone-error">
                                            {props.errors.mobile}
                                        </p>
                                    )}  
                            </div>
                            <div>
                                <Row>
                                    <Col md={4}>
                                        <div className="mb-4">
                                            <div className="">
                                                <StochedSelect label="Profession" name="profession">
                                                    <option disabled value="">
                                                    Select type of profession
                                                    </option>
                                                    {professions &&
                                                    professions.map((type) => (
                                                        <option value={type.id} key={type.id}>
                                                        {type.name}
                                                        </option>
                                                    ))}
                                                </StochedSelect>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-4">
                                            <StochedSelect label="Interest" name="interest">
                                                <option disabled value="">
                                                Select your interest
                                                </option>
                                                {interests &&
                                                interests.map((type) => (
                                                    <option value={type.id} key={type.id}>
                                                    {type.name}
                                                    </option>
                                                ))}
                                            </StochedSelect>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-4">
                                            <StochedSelect label="How you find us?" name="link">
                                                <option disabled value="">
                                                Select option
                                                </option>
                                                {socials &&
                                                socials.map((type) => (
                                                    <option value={type.id} key={type.id}>
                                                    {type.name}
                                                    </option>
                                                ))}
                                            </StochedSelect>
                                        </div>
                                    </Col>
                    
                                </Row>
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">Tell us about yourself </Label>
                                <textarea
                                name="description"
                                className="form-control"
                                rows="3"
                                placeholder="Enter your business description here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.description || ""}
                                />
                                <FormFeedback>{props.errors.description}</FormFeedback>
                            </div>

                            <p className="text-muted small">
                                To help us reach out to you when we go live or on our latest updates, please provide a valid email address.
                            </p>

                            <div className="mb-4 d-flex justify-content-end">
                                <button
                                className="btn btn-lg btn-primary px-4"
                                type="submit"
                                disabled={loading}
                                >
                                    {loading ? <PulseLoader color="#fff" size={7} /> : "Submit"}
                                
                                </button>
                            </div>
                            </form>
                        )}
                        </Formik>
                    </Col>
                </Row>

            </section>
        </div>
    </>

  )
}

export default Interest
