import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: null,
  isLoading: false,
};

export const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutUser: (state) => {
        state.isLoading = true
    },
    logoutSuccess: (state, action) => {
      state.response = action.payload.data;
      state.isLoading = false
    },

    logoutFailure: (state) => {
        state.isLoading = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { logoutUser, logoutSuccess, logoutFailure } =
  logoutSlice.actions;

export const logoutSelector = (state) => state.logout;

export default logoutSlice.reducer;
