import React from "react";
import { useField } from "formik";
import { Label, FormFeedback } from "reactstrap";

const StochedSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4">
      <Label htmlFor={props.id || props.name} className="form-label">
        {label}
      </Label>
      <select
        {...field}
        {...props}
        className={`form-select form-control ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
      />
      {meta.touched && meta.error ? (
        <div className="error invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default StochedSelect;
