import { call, put, takeEvery } from "redux-saga/effects";
import { coursesEnrolledFailure, coursesEnrolledSuccess, createEnrolFailure, createEnrolSuccess, createRecordFailure, createRecordSuccess, fetchCourseFailure, fetchCourseSuccess, getCourseFailure, getCourseSuccess, getCoursesFailure, getCoursesSuccess, getQuizFailure, getQuizScoreFailure, getQuizScoreSuccess, getQuizSuccess, getResourcesFailure, getResourcesSuccess, markChapterFailure, markChapterSuccess } from "./reducer";
import { toast } from "react-toastify";
import { fetchCourses, fetchCourse, fetchQuiz, enrolCourse, recordQuiz, enrolledCourses, chapterComplete, fetchQuizScore, fetchResources } from "../../api/requests/course";


//Fetch Courses
function* fetchAllCourses({ payload: query }) {
  try {
    const response = yield call(fetchCourses, query)
    yield put(getCoursesSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(getCoursesFailure(error));
  }
}

//Fetch A Course
function* fetchOneCourse({ payload: query }) {
  try {
      const response = yield call(fetchCourse, query)
      yield put(getCourseSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(getCourseFailure(error));
  }
}

//Fetch A Course
function* fetchACourse({ payload: query }) {
  try {
    const response = yield call(fetchCourse, query)
    yield put(fetchCourseSuccess(response.data.data));

  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(fetchCourseFailure(error));
  }
}


//Enrol for a course
function* createCourseEnrol({ payload: query }) {
  try {
      const response = yield call(enrolCourse, query)

      // Open a new tab with the payment link URL
      const paymentLink = response.data.data.paymentLink;

      if (paymentLink) {
        // Open a new tab with the payment link URL
        window.open(paymentLink, '_blank');
      } else {
        // Handle the case where paymentLink is empty
        // console.error('Payment link is empty');
      }

      yield put(createEnrolSuccess(response.data.data));
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        }) 

  } catch (error) {

    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(createEnrolFailure(error));
  
  }
}

//Fetch Quiz
function* fetchCourseQuiz({ payload: query }) {
  try {
      const response = yield call(fetchQuiz, query)
      console.log('Quiz', response)
      yield put(getQuizSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(getQuizFailure(error));
  }
}

//Fetch Quiz Score
function* fetchCourseQuizScore({ payload: query }) {
  try {
      const response = yield call(fetchQuizScore, query)

      yield put(getQuizScoreSuccess(response.data.data));
  } catch (error) {
  //   toast.error(error.response?.data.message, {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  // });
    yield put(getQuizScoreFailure(error));
  }
}

//Record Quiz
function* storeQuiz({ payload: query }) {
  try {
      const response = yield call(recordQuiz, query)
      yield put(createRecordSuccess(response.data.data));
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }) 
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(createRecordFailure(error));
  }
}

//Students Enrolled Courses
function* studentEnrolledCourses({ payload: query }) {
  try {
      const response = yield call(enrolledCourses, query)
      yield put(coursesEnrolledSuccess(response.data.data));
  } catch (error) {

    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(coursesEnrolledFailure(error));
  }
}

//Mark Chapter Completion Status
function* markChapterCompletion({ payload: query }) {
  try {
      const response = yield call(chapterComplete, query)
      yield put(markChapterSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(markChapterFailure(error));
  }

}

//Fetch Resources
function* fetchAllResources({ payload: query }) {
  let payload = {
    'courseId': query,
  }
  try {
    const response = yield call(fetchResources, payload)

    yield put(getResourcesSuccess(response.data.data));
  } catch (error) {
    toast.error(error.response?.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
  });
    yield put(getResourcesFailure(error));
  }
}

function* courseSaga(){
  yield takeEvery('course/getCourses', fetchAllCourses)
  yield takeEvery('course/getCourse', fetchOneCourse)
  yield takeEvery('course/fetchCourse', fetchACourse)
  yield takeEvery('course/getQuiz', fetchCourseQuiz)
  yield takeEvery('course/getQuizScore', fetchCourseQuizScore)
  yield takeEvery('course/createEnrol', createCourseEnrol)
  yield takeEvery('course/createRecord', storeQuiz)
  yield takeEvery('course/coursesEnrolled', studentEnrolledCourses)
  yield takeEvery('course/markChapter', markChapterCompletion)
  yield takeEvery('course/getResources', fetchAllResources)
}

export default courseSaga