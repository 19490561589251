import React, { useEffect } from 'react'
import { Container, Row, Col, Input, Label, FormFeedback, Card, CardBody } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';

import { useParams, Link as RouterLink } from "react-router-dom";
import banner from '../../assets/images/banner2.jpeg'
import author from '../../assets/images/users/eric.jpg'
import { useSelector, useDispatch } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { createEnrol, refreshCourse } from '../../store/course/reducer';
import _ from 'lodash';

const CoursePreview = () => {
    const dispatch = useDispatch()
    const history = useHistory();

//   const { id } = useParams();

  const { fetchedCourse, loading, response, selected } = useSelector(state => ({
    fetchedCourse: state.course.preview,
    loading: state.course.isLoading,
    response: state.course.response,
    selected: state.course.selected,
  }))

//   console.log('The selected course', selected)


  useEffect(() => {
    dispatch(refreshCourse())
  }, [])

  useEffect(() => {
    // if (_.isEmpty(response) ){
    //     return;
    // }else{
    //     history.push(`/enrolled/${fetchedCourse._id}`)

    // }
    // console.log('course enrol response', response)
  }, [ response ])


  // Get the number of chapters in each section
    const numberOfChaptersInSection = fetchedCourse.sections?.map(section => section?.chapters.length);

    // Calculate the sum of chapters in all sections
    const sumOfChapters = numberOfChaptersInSection?.reduce((acc, val) => acc + val, 0);



  const handleEnrol = () => {
    dispatch(createEnrol(fetchedCourse._id))
  }

  const handleView = () => {
    console.log('view course')
    history.push(`/enrolled/${fetchedCourse._id}`)
  }

  return (
    <div className='page-content'>
        <div className=''>
            <RouterLink to="/dashboard" className="btn py-0">
                <i className="mdi mdi-arrow-u-left-top fs-4 font-size-24"></i>
            </RouterLink>
        </div>
        <Container fluid className="ms-0 mt-2">
        <Row>
            <Col xs={6}>
                <img className="img-fluid" src={fetchedCourse?.courseBanner} alt=""/>
                <h3 className="font-weight-bold mt-3">
                        {fetchedCourse?.title}
                </h3>
                <p className="mb-4 text-muted">{fetchedCourse?.description}</p>
                
                <h3 className='font-weight-bold mb-3'>${fetchedCourse?.price}</h3>

                <Row>
                    <Col xs={3}>
                        <div className='d-flex'>
                            <div className="avatar-sm me-2">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="mdi mdi-alpha-s-box"></i> 
                                </span>
                            </div>
                            <p className='text-muted mt-2 font-size-15 fw-bold'>{fetchedCourse.sections?.length} Sections</p>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='d-flex'>
                            <div className="avatar-sm me-2">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="mdi mdi-alpha-c-box"></i> 
                                </span>
                            </div>
                            <p className='text-muted mt-2 fw-bold'>{sumOfChapters} Chapters </p>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='d-flex'>
                            <div className="avatar-sm me-2">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="mdi mdi-alpha-p-box"></i> 
                                </span>
                            </div>
                            <p className='text-muted mt-2 fw-bold'>1 Project</p>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='d-flex'>
                            <div className="avatar-sm me-2">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="mdi mdi-alpha-q-box"></i> 
                                </span>
                            </div>
                            <p className='text-muted mt-2 fw-bold'>1 Quiz</p>
                        </div>
                    </Col>
                </Row>

                <hr />

                <div className="d-flex mb-4">
                    <div className="flex-shrink-0 me-3">
                        <img className="rounded avatar-md" src={author} alt="" />
                    </div>
                    <div className="flex-grow-1">
                        <h5>Eric Maass</h5>
                        <p className="mb-0">Eric retired as Senior Director and Technical Fellow at Medtronic, leading and Coaching successful new product and technology development projects. Eric joined Medtronic in October 2009, after 30 years with Motorola in roles ranging from Research and Development through Manufacturing, to Director of Operations for a $160 Million business and Director of Design and Systems Engineering for the Wireless group of Motorola SPS.</p>

                        <p className="mb-0 mt-2">Eric was a co-founder of Six Sigma, and had been the Lead Master Black Belt for DFSS at Motorola.  His book, Applying DFSS to Software and Hardware Systems, provides clear step-by-step guidance on applying DFSS for developing innovative and compelling new products and technologies, while managing the business, schedule and technical risks. His newest book, “Flawless Launches – Profitable Products” shares methods that have led to very successful product launches.</p>
                    </div>
                </div>
                <div className='d-flex'><button
                    className="btn btn-lg btn-primary px-4 d-block me-2"
                    type="submit"
                    onClick={handleEnrol}
                >
                    {loading ? <PulseLoader color="#fff" size={7} /> : "Pay Now"}

                </button>
                <button
                    className="btn btn-lg btn-outline-primary px-4 d-block"
                    type="submit"
                    onClick={handleView}
                >
                    View Course

                </button></div>

            </Col>
        </Row>
        </Container>
    </div>
  )
}

export default CoursePreview
