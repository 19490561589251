import { call, put, takeEvery } from "redux-saga/effects";
import { emailConfirm, signInUser } from "../../../api/requests/auths";
import { verifyEmailFailure, verifyEmailSuccess } from "./reducer";
import { toast } from "react-toastify";

function* confirmEmail({ payload: values }) {

    try {
        const response = yield call(emailConfirm, values)

        console.log('email verify response', response)

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          }) 

        yield put(verifyEmailSuccess(response));

    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
      yield put(verifyEmailFailure(error));
    
    }
  }

  function* confirmEmailSaga(){
    yield takeEvery('emailConfirmation/verifyEmail', confirmEmail)
  }

  export default confirmEmailSaga