import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Form, Input, Label, FormFeedback} from "reactstrap";
import { CameraEditIcon, EditIcon } from '../../assets/icons/Custom';
import emptyImage from "../../assets/images/users/empty.png"
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import { createProfilePicture } from '../../store/actions';
import StochedSelect from '../../components/CommonForBoth/Stoched/StochedSelect';
import { FadeLoader, PulseLoader } from 'react-spinners';

import axios from "axios";


import { getUser } from '../../store/auths/login/reducer';
import { updateProfile } from '../../store/auths/profile/reducer';
import { ENDPOINTS } from '../../api/constants';
import baseUrl from '../../api';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
// const baseUrl = process.env.REACT_APP_API_BASE;


const ProfileTab = () => {
    const dispatch = useDispatch();

    const [showBhm, setShowBhm] = useState(false)
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [profileData, setProfileData] = useState({})

    const { user, loading } = useSelector((state) => ({
        user: state.login.user,    
        loading: state.profile.isLoading,     
      }));

      console.log('User Profile', user)

    // useEffect(() => {
    //     dispatch(getUser())
    // }, [user])

    useEffect(() => {
        const data = {
            'firstName': user.firstName,
            'lastName': user.lastName,
            'email': user.email,
            'mobile': user.mobile, 
            'address': user.address,
            'profession': user.profession,
            'interest': user.interest,
            'howYouheardAboutUs': user.howYouheardAboutUs,
        }

        setProfileData(data)
    }, [user])

    const professions = [
        {'id': 'student', 'name': 'Student'},
        {'id': 'professional', 'name': 'Professional'},
        {'id': 'others', 'name': 'Others'},
    ]

    const interests = [
        {'id': 'engineering', 'name': 'Engineering'},
        {'id': 'design', 'name': 'Design'},
        {'id': 'marketing', 'name': 'Marketing'},
        {'id': 'finance', 'name': 'Finance'},
        {'id': 'research', 'name': 'Research'},
        {'id': 'development', 'name': 'Development'},
        {'id': 'others', 'name': 'Others'},
    ]

    const socials = [
        {'id': 'linkedin', 'name': 'Linkedin'},
        {'id': 'twitter', 'name': 'Twitter'},
        {'id': 'google', 'name': 'Google'},
        {'id': 'facebook', 'name': 'Facebook'},
        {'id': 'word of mouth', 'name': 'Word of Mouth'},
        {'id': 'others', 'name': 'Others'},
    ]

  const inputRef = useRef();

  const _handleSubmit = (values) => {
    const payload = {
        'firstName': values.firstName,
        'lastName': values.lastName,
        'email': values.email,
        'mobile': values.mobile, 
        'address': values.address,
        'profession': values.profession,
        'interest': values.interest,
        'howYouheardAboutUs': values.howYouheardAboutUs,
        'userId': user._id,
    }
    dispatch(updateProfile(payload))
    dispatch(getUser())
  };

  const phoneRegex = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );


const ValidationSchema = Yup.object().shape({
        email: Yup.string().required("Required"),
        mobile: Yup.string()
        .matches(phoneRegex, "Enter a valid phone number")
        .required("Required"),
    });

  const handleFileSelected = async(e) => {
    const file = e.target.files[0]
    if (!file) return;

    const isValidFileType = file.type === "image/jpeg" || file.type === "image/png";
    const isValidFileSize = file.size <= MAX_FILE_SIZE;

    if (!isValidFileType) {
      alert("Invalid file type. Only JPEG and PNG files are allowed.");
      return;
    }

    if (!isValidFileSize) {
      alert(`File is too large. Maximum file size is ${MAX_FILE_SIZE / 1024 / 1024} MB.`);
      return;
    }

    //Upload Picture
    try {
        // Upload the video file to the endpoint and get the URL
        const formData = new FormData();
        formData.append('image', file);
        const userId = localStorage.getItem("user_id")
        // console.log('profile upload response', userId)

        setIsLoading(true);

        let url = `${baseUrl}` + ENDPOINTS.updatePicture  + `${userId}`;
        const response = await axios.put(url, formData);
        const profileUrl = response.data.data.profilePicture;
        console.log('profile upload response', response)

        setIsLoading(false);
        setImageUrl(profileUrl)

    } catch (error) {
        console.error('Error uploading profile picture:', error);
    }
    
    dispatch(getUser())

  }

  return (
    <div>
        <Row className="mb-5">
            <Col xs={6}>
                <h6 className="profile-title pt-3 font-size-18">
                Profile
                </h6>
                <div className="pt-3 pb-3 pp-rel">
                {imageUrl ? (
                    <img src={imageUrl} alt="Profile" className="rounded-circle avatar-xl"/>
                ) : (
                    <img src={user && user.profilePicture ? user.profilePicture : emptyImage } alt="No profile picture" className="rounded-circle avatar-xl"/>
                )}
                {isLoading && <span className='pp-spinner'><FadeLoader color="#03A48B" size={1} /></span>}
                <div
                    className="pp-abs pb-1 rounded-circle"
                    onClick={() => inputRef.current.click()}
                >
                    <span className='mx-1'><input className="upload-input" type="file" accept="image/jpeg, image/png" ref={inputRef} onChange={handleFileSelected}/>
                        <CameraEditIcon/>
                    </span>
                </div>

                </div>
                <span className='mt-0 pp-size'><p>Max. Image size of 2MB</p></span>

                <div className="pt-2 pb-3">
                    <Formik
                       initialValues={user? profileData : {
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "", 
                        address: "",
                        profession: '',
                        interest: '',
                        howYouheardAboutUs: '',
            
                    }}
                    enableReinitialize = {true}

                    onSubmit={_handleSubmit}
                    validationSchema={ValidationSchema}
                    >
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Row className='mb-3'>
                                <Col md={6}>
                                    <div className="">
                                        <Label className="form-label">Firstname</Label>
                                        <Input
                                        name="firstName"
                                        type="text"
                                        placeholder="Enter first name"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.firstName || ""}
                                        invalid={
                                            props.touched.firstName && props.errors.firstName
                                            ? true
                                            : false
                                        }
                                        />
                                        {props.touched.firstName && props.errors.firstName ? (
                                        <FormFeedback type="invalid">
                                            {props.errors.firstName}
                                        </FormFeedback>
                                        ) : null}
                                    </div>

                                </Col>
                                <Col md={6} className='mb-0'>
                                    <div className="">
                                        <Label className="form-label">Lastname</Label>
                                        <Input
                                        name="lastName"
                                        type="text"
                                        placeholder="Enter last name"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.lastName || ""}
                                        invalid={
                                            props.touched.lastName && props.errors.lastName
                                            ? true
                                            : false
                                        }
                                        />
                                        {props.touched.lastName && props.errors.lastName ? (
                                        <FormFeedback type="invalid">
                                            {props.errors.lastName}
                                        </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                  
                            </Row>
                            <Row className='mb-3'>
                                <Col md={6}>
                                    <div className="">
                                        <Label className="form-label">Email</Label>
                                        <Input
                                        name="email"
                                        type="email"
                                        placeholder="Enter email address"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email || ""}
                                        disabled='true'
                                        invalid={
                                            props.touched.email && props.errors.email
                                            ? true
                                            : false
                                        }
                                        />
                                        {props.touched.email && props.errors.email ? (
                                        <FormFeedback type="invalid">
                                            {props.errors.email}
                                        </FormFeedback>
                                        ) : null}
                                    </div>

                                </Col>
                                <Col md={6} className='mb-0'>
                                    <Label className="form-label">Mobile Number</Label>
                                    <PhoneInput
                                        name="mobile"
                                        country={"us"}
                                        // onlyCountries={["ke", "ng", "gh"]}
                                        value={props.values.mobile}
                                        inputClass={
                                            props.touched.mobile &&
                                            props.errors.mobile &&
                                            "is-invalid"
                                        }
                                        onBlur={() => props.setFieldTouched("mobile", true)}
                                        onChange={(value) => props.setFieldValue("mobile", value)}
                                        invalid={
                                            props.touched.mobile && props.errors.mobile ? true : false
                                        }
                                    />
                                    {props.touched.mobile && props.errors.mobile && (
                                    <p className="text-danger phone-error">
                                        {props.errors.mobile}
                                    </p>
                                    )}  
                                </Col>
                  
                            </Row>

                            <div className="mb-3">
                                <Label className="form-label">Address</Label>
                                    <Input
                                        name="address"
                                        type="text"
                                        placeholder="Enter email address"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.address || ""}
                                    />
                            </div>

                            <Row>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <div className="">
                                            <StochedSelect label="Profession" name="profession">
                                                <option disabled value="">
                                                Select type of profession
                                                </option>
                                                {professions &&
                                                professions.map((type) => (
                                                    <option value={type.id} key={type.id}>
                                                    {type.name}
                                                    </option>
                                                ))}
                                            </StochedSelect>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <StochedSelect label="Interest" name="interest">
                                            <option disabled value="">
                                            Select your interest
                                            </option>
                                            {interests &&
                                            interests.map((type) => (
                                                <option value={type.id} key={type.id}>
                                                {type.name}
                                                </option>
                                            ))}
                                        </StochedSelect>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <StochedSelect label="Where did you hear about us?" name="link">
                                            <option disabled value="">
                                            Select option
                                            </option>
                                            {socials &&
                                            socials.map((type) => (
                                                <option value={type.id} key={type.id}>
                                                {type.name}
                                                </option>
                                            ))}
                                        </StochedSelect>
                                    </div>
                                </Col>
                  
                            </Row>
                            
                            <div className="mb-4 d-flex justify-content-end">
                                <button
                                className="btn btn-lg btn-primary px-4 d-block"
                                type="submit"
                                disabled={loading}
                                >
                                    {loading ? <PulseLoader color="#fff" size={7} /> : "Save changes"}
                                </button>
                            </div>

                        </form>
                    )}
                    </Formik>
                        
                </div>
            </Col>
        </Row>

    </div>
  )
}

export default ProfileTab
