import React, { useState } from 'react'
import { Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, Collapse, NavbarToggler } from 'reactstrap';
import stoched from './../../assets/images/stoched_logo.png'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState(true)

    const toggle = () => setIsOpen(!isOpen);


  return (
    <div>
        <Navbar light expand="md" className=''>    
            <Container className=''>
                <NavbarBrand href="/welcome" >
                    <img src={stoched} alt="Logo" className='mt-2'/>
                </NavbarBrand>

                <div className='mb-2 d-flex float-end'>
                        <NavbarToggler onClick={toggle}/>
                </div>
                <div className='float-end'>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto landing" navbar>
                            <NavItem>
                                <NavLink href="/welcome">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/certificate">Certificate</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/feedback">Feedback</NavLink>
                            </NavItem>

                            {status ? (
                            <>
                                    <NavItem className='head'>
                                        <NavLink href="/login" className='m-0'> <button className='btn btn-outline-primary waves-effect waves-light mx-sm-2 nav-head'>Student Login</button></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        {/* <NavLink href="http://stagingauthor.stoched.com" className='m-0'> <button className='btn btn-primary waves-effect waves-light'>Author Login</button></NavLink> */}
                                        <NavLink href="http://author.stoched.com" className='m-0'> <button className='btn btn-primary waves-effect waves-light'>Author Login</button></NavLink>
                                    </NavItem>
                            </>
                                
                            ) : (
                                <NavItem>
                                    <NavLink href="/interest" className='m-0'>Interest Form</NavLink>
                                </NavItem>
                            )} 
                        </Nav>
                    </Collapse>
                </div>
            </Container>
        </Navbar>
    </div>
  )
}

export default Header
