import React, { useState } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody, } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';

const Landing = () => {
    document.title = "Landing | Stoched";

  return (
    <div>
        <Header/>
        <section
          className="hero-section hero-image"
          id="home"
          style={{ position: "relative" }}
        >
            <Container>
                <Row className="justify-content-center py-5">
                <Col xs={12} sm={12} md={4} lg={5}>
                    <div className="hero-wrapper mb-4 py-5">
                    <h1
                        className="hero-title mb-2"
                        style={{ fontSize: "3.0rem" }}
                    >
                        Eric Maass
                    </h1>
                    <h4 className='font-size-22'>Co-Founder of Design for Six Sigma</h4>

                    <div className='mt-4 py-3 font-size-16 font-weight-bold'>
                        <p className=''> 500+ Certified Green Belts</p>
                        <p> 100+ Certified Black Belts</p>
                        <p> 50+ Certified Master Black Belts</p>
                    </div>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={8} lg={7}>
                    <div className="hero-image-inner">
                        <div className="embed-responsive embed-responsive-4by3 ratio ratio-16x9">
                            <iframe
                            title="tes2"
                            className="embed-responsive-item"
                            src="https://www.youtube.com/embed/rQzF-fLnKt8"
                            />
                    </div>
                    </div>
                </Col>
                </Row>
            </Container>
        </section>
        <section className="section">
          <Container>
            <Row>
            <h5 className="mb-4"> Design for Six Sigma Green Belt Topics </h5>
              <Col lg={4}>
                <Card>
                    <CardHeader>
                        <h5 className="card-title">Why get certified?</h5>
                    {/* <p className="card-title-desc">
                        Aspect ratios can be customized with modifier className.
                    </p> */}
                    </CardHeader>
                    <CardBody className='p-0'>
                    <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                        <iframe
                        title="test1"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/Hi4jyFxe76I"
                        />
                    </div>
                    </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                    <CardHeader>
                        <h5 className="card-title">How to get certified?</h5>
                    </CardHeader>
                    <CardBody className='p-0 card-height'>
                    <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                        <iframe
                        title="test1"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/MvJQa2RlhbM"
                        />
                    </div>
                    </CardBody>
              </Card>
              </Col>
              <Col lg={4}>
                <Card>
                    <CardHeader>
                        <h5 className="card-title">What will you learn?</h5>
                    </CardHeader>
                    <CardBody className='p-0'>
                    <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                        <iframe
                        title="test1"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/i3g3-rQZBDc"
                        />
                    </div>
                    </CardBody>
                </Card>
              </Col>
              {/* <Col lg={3}>
                <Card>
                    <CardHeader>
                    <h5 className="card-title">Other resources</h5>
                    </CardHeader>
                    <CardBody className='p-0'>
                    <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                        <iframe
                        title="test1"
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                        />
                    </div>
                    </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </section>
       <Footer/>
    </div>
  )
}

export default Landing
