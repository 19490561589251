import React, { useEffect, useState } from "react";
import { TimeoutWarning } from "../../components/CommonForBoth/Modal/timeoutwarning";

import {
  addEventListeners,
  removeEventListeners,
} from "../../helpers/utils/eventListenerUtil";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/auths/logout/reducer";



export const TimeoutLogic = () => {
  const dispatch = useDispatch();

  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  

  useEffect(() => {
    const createTimeout1 = () => setTimeout(()=>{ 
      setWarningModalOpen(true);
  },1000000)


    const createTimeout2 = () => setTimeout(() => {
        // Implement a sign out function here
        dispatch(logoutUser());
        
    }, 10000);

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);

  const onRequestClose = () => {
    setWarningModalOpen(false);
  };

  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutWarning
          isOpen={isWarningModalOpen}
          onRequestClose={onRequestClose}
          title="Session Timeout"
          description="You're being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you will be logged off automatically"
          primaryButton="Stay logged in"
        />
      )}
    </div>
  );

}