import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import Landing from "../pages/Landing";
import Certificate from "../pages/Certificate";
import Course from "../pages/Course";
import Settings from "../pages/Settings";
import PaidCourses from "../pages/PaidCourses";
import Interest from "../pages/Interest";
import ResetPassword from "../pages/Authentication/ResetPassword";
import EmailConfirmation from "../pages/Authentication/EmailConfirmation";
import CoursePreview from "../pages/CoursePreview";
import Feedback from "../pages/Feedback";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/my-courses", component: PaidCourses },
  { path: "/settings", component: Settings },
  { path: "/dashboard/:courseId", component: Course },
  { path: "/enrolled/:courseId", component: Course },
  { path: "/course-preview/:courseId", component: CoursePreview },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/welcome" /> },
];

const authRoutes = [
  //landing page 
  {path: "/welcome", component: Landing},
  {path: "/interest", component: Interest},
  {path: "/feedback", component: Feedback},
  {path: "/certificate", component: Certificate},

  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset-password/:id", component: ResetPassword },
  { path: "/email-confirmation/:id", component: EmailConfirmation },

];

export { userRoutes, authRoutes };
