import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Link } from "react-router-dom";

const CertificateBody = () => {
  return (
    <div className='py-5'>
      <Container className='py-3'>
            <Row>
                <Col lg={12}>
                    <div className="text-center py-4">
                        <div className="maintenance-cog-icon text-primary pt-4">
                            <i className="mdi mdi-certificate display-3"></i>
                        </div>
                        <h3 className="mt-4">Design for Six Sigma Belt Journey</h3>
                        <p>Steps to getting certified</p>

                        <div className="mt-4">
                            <Row>
                                <Col md={4}>
                                    <div className="mt-4 maintenance-box">
                                        <div className="p-4">
                                            <div className="avatar-md mx-auto">
                                                <span className="avatar-title bg-soft-primary rounded-circle">
                                                    <i className="font-size-24 text-primary">1</i>
                                                </span>
                                            </div>

                                            <h5 className="font-size-15 text-uppercase mt-4">Embark on the Journey</h5>
                                        
                                            <p className="text-muted mb-0">Begin mastering predictive engineering essentials.</p>
                                            <Link to="/register"> Register</Link>
                                     
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mt-4 maintenance-box">
                                        <div className="p-4">
                                            <div className="avatar-md mx-auto">
                                                <span className="avatar-title bg-soft-primary rounded-circle">
                                                    <i className="font-size-24 text-primary">2</i>
                                                </span>
                                            </div>
                                            <h5 className="font-size-15 text-uppercase mt-4">
                                                Take the course</h5>
                                            <p className="text-muted mb-0">Dive into practical stochastic engineering skills.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mt-4 maintenance-box">
                                        <div className="p-4">
                                            <div className="avatar-md mx-auto">
                                                <span className="avatar-title bg-soft-primary rounded-circle">
                                                    <i className="font-size-24 text-primary">3</i>
                                                </span>
                                            </div>
                                            <h5 className="font-size-15 text-uppercase mt-4">
                                                Get Stoched</h5>
                                            <p className="text-muted mb-0">Validate expertise with the Stoched Certification.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default CertificateBody
