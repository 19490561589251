import PropTypes from "prop-types"
import React, { useState } from "react"

import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"
import { PulseLoader } from 'react-spinners';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
// import { loginUser, socialLogin } from "../../store/actions"

// import images
import stoched from "../../assets/images/stoched_.png"


//Import config
import { facebook, google } from "../../config"
import CarouselPage from "../AuthenticationInner/CarouselPage"
import { createLogin } from "../../store/auths/login/reducer";

const Login = props => {

  const [showHidePassword, setShowHidePassword] = useState(false);

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.login.isLoading,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, {resetForm}) => {
      dispatch(createLogin(values))
      resetForm({values: ''})
    }
  });


  document.title= "Login | Stoched";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 mt-5">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/welcome" className="d-block auth-logo">
                        <img src={stoched} alt="" width="80" height="80" />
                      </Link>
                    </div>
                    <div className="auth-content">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">Sign in to continue to Stoched.</p>
                      </div>
                     <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group dashpass">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={showHidePassword ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                           {!validation.errors.password && (
                            <span
                                className="input-group-text"
                                onClick={() =>
                                setShowHidePassword(!showHidePassword)
                                }
                            >
                                {showHidePassword ? (
                                <i className="mdi text-muted fs-4 mdi-eye"></i>
                                ) : (
                                <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                                )}
                            </span>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                              <Link to="/forgot-password" className="float-end">
                                <label
                                  className="form-check-label"
                                  htmlFor="remember-check"
                                >
                                  Forgot Password
                                </label>
                              </Link>
                          </div>
                        </div>

                        <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={
                                  !(validation.dirty && validation.isValid) ||
                                  validation.isSubmitting
                                }
                              >
                                {loading ? <PulseLoader color="#fff" size={7} /> : "Login"}

                              </button>
                            </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">Don't have an account ? <Link to="/register"
                          className="text-primary fw-semibold"> Sign up </Link> </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}