import { call, put, takeEvery } from "redux-saga/effects";
import { register, signInUser } from "../../../api/requests/auths";
import { registerFailure, registerSuccess } from "./reducer";
import { toast } from "react-toastify";

function* registerUser({ payload: user }) {
    
    try {
        const response = yield call(register, {
            'firstName': user.firstName,
            'lastName': user.lastName,
            'email': user.email,
            'password': user.password,
            'user_type_id': 'student'
        })

        yield put(registerSuccess(response));
        console.log('response',response)

        toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }) 

    } catch (error) {
        // console.log('register response', error)
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

      yield put(registerFailure(error));
    }
  }

  function* registerSaga(){
    yield takeEvery('register/createRegister', registerUser)
  }

  export default registerSaga