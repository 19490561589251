import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from 'reactstrap';

import img1 from "../../assets/images/users/eric.jpg";
import img2 from "../../assets/images/users/payal.jpg";

const items = [
    {
        id: 1,
        img: img1,
        name: "Eric Maass",
        designation: "Co-Founder of Design for Six Sigma",
        description: "Stochastic modeling has provided competitive advantages for enterprises, for products, and for the engineers who learn and apply these approaches!"
    },
    {
        id: 2,
        img: img2,
        name: "Payal Guerrero",
        designation: "Product Manager",
        description: "One of the most intriguing experiences in my career has been in realising the power of predictive engineering in applications of marketing and sales - the core lifeline of any business."
    },
];

const CarouselPage = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div className="carousel-item active">
                    <div className="testi-contain text-white">
                        <i className="bx bxs-quote-alt-left text-success display-6"></i>

                        <h4 className="mt-4 fw-medium lh-base text-white">“{item.description}”
                        </h4>
                        <div className="mt-4 pt-3 pb-5">
                            <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                    <img src={item.img} className="avatar-md img-fluid rounded-circle" alt="..." />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">{item.name}
                                    </h5>
                                    <p className="mb-0 text-white-50">{item.designation}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CarouselItem>
        );
    });

    return (
        <React.Fragment>
            <div className="col-xxl-8 col-lg-8 col-md-7">
                <div className="auth-bg pt-md-5 p-4 d-flex">
                    <div className=""></div>
                    <ul className="bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-7">
                            <div className="p-0 p-sm-4 px-xl-0">
                                {/* <div id="reviewcarouselIndicators" className="carousel slide" data-bs-ride="carousel">
                                    <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                                    <Carousel
                                        activeIndex={activeIndex}
                                        next={next}
                                        previous={previous}
                                    >
                                        {slides}

                                    </Carousel>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CarouselPage;