export const TOKEN = localStorage.getItem("token");


export const ENDPOINTS = {
  //Authentication
  login: "student/login",
  register: 'registration',
  confirmEmail: 'account/confirmation/',
  logout: 'logout',
  forgotPassword: 'forgotPassword',
  resetPassword: 'password/setnew/',
  changePassword: 'reset/',
  updateProfile: 'account/edit/',
  loggedInUser: '',
  refreshToken: "generate-token",


  //Course
  fetchCourse: 'course/',
  fetchCourses: 'students/list-courses',
  updatePicture: 'update/profile-picture/',
  fetchQuiz: 'student/get-quiz/',
  fetchQuizScore: 'student/quiz-score/',
  enrolCourse: 'payment/create/',
  recordQuiz: 'course/record-quiz/',
  submitProject: 'course/submit-project/',
  enrolledCourses: 'students/enrolled-courses',
  chapterComplete: 'course/mark-video-complete/',
  downloadProject: 'course/download-project/',
  fetchResources: 'course/doc/',
  downloadResource: 'api/course/doc/download/',
  
};
