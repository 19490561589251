import React from "react";
// import ReactDOM from "react-dom/client";
import { createRoot } from "react-dom/client";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <React.Fragment>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.Fragment>
//   </Provider>
// );


const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/index.html">
            <App />
          </BrowserRouter>
    </PersistGate>
  </Provider>
);

createRoot(document.getElementById("root")).render(app);