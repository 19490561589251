const truncateString = function (str, maxLength) {
    if (str?.length <= maxLength) {
      return str;
    }
  
    return str?.substring(0, maxLength) + "...";
  }
  
 export {
    truncateString,
 }