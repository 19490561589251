import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Container, Input, Label, Form, FormFeedback  } from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom"
import { PulseLoader } from 'react-spinners';

// import images
import stoched from "../../assets/images/stoched_.png"

import CarouselPage from "../AuthenticationInner/CarouselPage"
import { createRegister } from "../../store/auths/register/reducer";

const Register = props => {

  //meta title
  document.title = "Register | Stoched";

  const [showHidePassword, setShowHidePassword] = useState(false);

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.register.isLoading,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your Firstname"),
      lastName: Yup.string().required("Please Enter Your Lastname"),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: (values, {resetForm}) => {
      dispatch(createRegister(values));
      resetForm({values: ''})
    }
  });

  useEffect(() => {
    dispatch(apiError(""))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className=" mt-4 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={stoched} alt="" width="60" height="60" /> <span className="logo-txt"></span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">Get your Stoched account now.</p>
                      </div>

                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                        <div className="mb-3">
                          <Label className="form-label">Firstname</Label>
                          <Input
                            name="firstName"
                            type="text"
                            placeholder="Enter firstname"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName && validation.errors.firstName ? true : false
                            }
                          />
                          {validation.touched.firstName && validation.errors.firstName ? (
                            <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Lastname</Label>
                          <Input
                            name="lastName"
                            type="text"
                            placeholder="Enter lastname"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName && validation.errors.lastName ? true : false
                            }
                          />
                          {validation.touched.lastName && validation.errors.lastName ? (
                            <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group dashpass">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={showHidePassword ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                           {!validation.errors.password && (
                            <span
                                className="input-group-text"
                                onClick={() =>
                                setShowHidePassword(!showHidePassword)
                                }
                            >
                                {showHidePassword ? (
                                <i className="mdi text-muted fs-4 mdi-eye"></i>
                                ) : (
                                <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                                )}
                            </span>
                            )}
                          </div>
                        </div>
                        
                        <div className="mb-4">
                          <p className="mb-0">
                            By registering you agree to the Stoched{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            disabled={
                              !(validation.dirty && validation.isValid) ||
                              validation.isSubmitting
                            }
                          >
                              {loading ? <PulseLoader color="#fff" size={7} /> : "Register"}

                          </button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">Already have an account ? <Link to="/login"
                          className="text-primary fw-semibold"> Login </Link> </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register