import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
// import { userCreatePassword, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useParams } from "react-router-dom";

// import images
import logo from "../../assets/images/stoched_.png";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { verifyEmail } from "../../store/auths/emailConfirmation/reducer";

import { PulseLoader } from 'react-spinners';

// import loginbg from "../../assets/images/loginbg.png";

// this is shown after success

const EmailConfirmation = (props) => {

  const dispatch = useDispatch()

  const { id } = useParams();

  function handleConfirm(){
    dispatch(verifyEmail(id))
    // console.log("param", id)
  }

  const { loading } = useSelector(state => ({
    loading: state.emailConfirmation.isLoading,
  }))

  //meta title
  document.title = "Stoched | Email Confirmation";


  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="mx-auto col-10 col-xl-7">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 ">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} height={"53px"} alt="stoched logo" />
                      </Link>
                    </div>

                    <div className="auth-content mt-5 text-center">

                        <div className="">
                            <h2 className="mb-0 mt-5">
                                Verify Your Email
                            </h2>
                            <p className="text-muted mt-3">
                                Click the button to confirm your email
                            </p>
                        </div>

                        <div className="mb-3">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              onClick={handleConfirm}
                            >
                                {loading ? <PulseLoader color="#fff" size={7} /> : "Confirm Email"}
                            </button>
                        </div>
                        
                        <div className="mt-2 text-center">
                          <p className="text-muted mb-0">
                            Email Confirmed?{" "}
                            <Link
                              to="/login"
                              className="text-primary fw-semibold"
                            >
                              {" "}
                              Login{" "}
                            </Link>{" "}
                          </p>
                        </div>
                    </div>
                    
                        
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailConfirmation;
