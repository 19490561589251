import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from 'react-spinners';
import { logoutUser } from '../../../store/auths/logout/reducer';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth:"500px",
    width: "100%",
    padding: "24px 32px",
    fontSize: "17px"
  },  
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarning = ({isOpen, onRequestClose, title, description, primaryButton }) => { 
  const dispatch = useDispatch();

  const { loggingOut, isRefreshing } = useSelector((state) => ({
    user: state.login.user,
    loggingOut: state.logout.isLoading,
    isRefreshing: state.login.isRefreshing,
  }));

  const onLogOffCall = () => {
    // Implement your logout functionality here, e.g. clear the users login cache or hit your signout server
    // dispatch(logoutUser(user.email));
    dispatch(logoutUser())
  }

  return (
    <div> 
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <h3 className='timeout-title'>{title}</h3>
        <div>{description}</div>
        <br/>
        <div className='float-end'>
          <button disabled={loggingOut} type="button" onClick={onLogOffCall} className='me-2 btn btn-outline-primary waves-effect waves-light'>
            {loggingOut ? <PulseLoader color="red" size={7} /> : "Log off"}  
          </button>
          <button type="button" onClick={onRequestClose} className='btn btn-primary waves-effect waves-light'>
            {isRefreshing ? <PulseLoader color="#fff" size={7} /> : primaryButton }  
          </button>
        </div>
      </Modal>
    </div>  
  );
}