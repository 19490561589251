import React, { useState, useEffect } from 'react'
import { Link, useHistory  } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { truncateString } from '../../helpers/utils_functions';
import debounce from "lodash.debounce";

import { useDispatch, useSelector } from 'react-redux';
import Empty from '@uiw/react-empty';
import { Skeleton } from '../../components/CommonForBoth/Stoched/Skeleton';
import { coursesEnrolled, getResources, refreshCourse, refreshQuiz, refreshResource, removeTitle, setTitle } from '../../store/course/reducer';
import ReactPaginate from "react-paginate";

import OrdersBagImg from "../../assets/images/orderbags.svg"


const index = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [searchValue, setSearchValue] = useState('')
    const [pageNumber, setPageNumber] = useState(0);
    const [courses, setCourses] = useState([])
    // const [courseSelected, setCourseSelected] = useState('')


    const { enrolled, loading, selected } = useSelector(state => ({
        enrolled: state.course.enrolled,
        selected: state.course.selected,
        loading: state.course.isLoading,
    }))

    // console.log('The selected course', selected)
    // console.log('enrolled courses__', enrolled)

     //Pagination
     const usersPerPage = 10;
     const pagesVisited = pageNumber * usersPerPage;
 
     const pageCount = Math.ceil(enrolled?.totalCourses / usersPerPage);
 
     const changePage = ({ selected }) => {
       setPageNumber(selected + 1);
     };

    useEffect(() => {
        dispatch(refreshQuiz())
        dispatch(refreshResource())
        dispatch(refreshCourse())
        dispatch(removeTitle())
        dispatch(setTitle('My Learning'))
    }, [])
 
   
    useEffect(() => {
        setCourses(enrolled?.courses)
    }, [enrolled])

    useEffect(() => {
        // Simulating the API call to fetch the data

        const query = {
            'pageNumber': pageNumber,
            'searchTerm': searchValue,
        }
        // let payload = {
        //     'courseId': course._id,
        //   }

        dispatch(coursesEnrolled(query))
        // dispatch(getResources(payload))

    }, [pageNumber, searchValue]);


    const handleClick = (course) => {
        setSelected(course)
        history.push(`/enrolled/${course._id}`)
        dispatch(selectCourse(course))
        // setIsShown(!isShown)
    };

     //Search

    // Debounced function to handle search submission
    const debouncedSearch = debounce((searchQuery) => {
        // Perform the actual search operation here
        console.log("Performing search with query:", searchQuery);
    }, 500); // Debounce delay set to 500 milliseconds


    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);

        // Trigger the debounced search function with the updated search value
        debouncedSearch(value);
    };

    const sizes = [1,2,3,4]

    
  return (
    <div className="page-content">
        <Container fluid>
            <Row className="mt-3">
                <div className='d-flex justify-content-between'>
                    {/* <h2 className='my-4'>My Courses</h2> */}

                    <form className="app-search d-none d-lg-block mb-4">
                        <div className="position-relative">
                            <input type="text" className="form-control" placeholder="Search..." 
                                value={searchValue}
                                onChange={handleSearchChange}/>
                            <button className="btn btn-primary" type="button"><i className="bx bx-search-alt align-middle"></i></button>
                        </div>
                    </form>
                </div>
               

                {loading ? (
                <Row>
                    {sizes?.map((size, i) => (
                        <Col xl={3} lg={4} sm={6} key={i}>
                            <Skeleton />
                        </Col>
                    ))}
                </Row>
                ) : courses?.length === 0 ? (
                <div className='my-5'>
                    <Empty
                        // img={OrdersBagImg}
                        // title="No Course Available"
                        // msg="Your courses will be displayed in this section"
                        description={
                            <span>
                              No Course Available
                            </span>
                          }
                    />
                </div>
                ) : (
                    <>
                        {courses?.map((course, i) => 
                            <Col xl={3} sm={6} key={i} onClick={() => handleClick(course)}>
                                <Link to={`/enrolled/${course.course._id}`}>
                                    <Card className='card-courses'>
                                        <img className="card-img-top img-fluid" src={course.course.courseBanner} alt="" height={20}/>
                                        <CardBody className="overflow-hidden position-relative">
                                            <div>
                                                <span className='bx widget-box-1-icon'><p className=''>ENROLLED</p></span>
                                            </div>
                                            <div className="faq-count">
                                                <h5 className="text-primary">${course.course.price}</h5>
                                            </div>
                                            <h5 className="mt-3">{course.course.title}</h5>
                                            <p className="text-muted mt-3 mb-0">{truncateString(course.course.description, 50)}</p>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>
                        )}
                    </>
                )}

                
            </Row>

            <div className='mx-0 mt-4'>
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                />
            </div>

        </Container>

    </div>
  )
}

export default index
