import React, { useState, useEffect } from 'react';
import OrdersBagImg from "../../assets/images/orderbags.svg"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, useHistory } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";
import Checkout from '../Checkout';
import CheckoutModal from '../../components/CommonForBoth/Stoched/CheckoutModal';
import { truncateString } from '../../helpers/utils_functions';
import { useDispatch, useSelector } from 'react-redux';
import { coursesEnrolled, getCourses, removeTitle, selectCourse, setTitle } from '../../store/course/reducer';
import { getUser } from '../../store/auths/login/reducer';
import ReactPaginate from "react-paginate";
import debounce from "lodash.debounce";
import { Skeleton } from '../../components/CommonForBoth/Stoched/Skeleton';
import Empty from '../../components/CommonForBoth/Stoched/Empty';
const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // const [isShown, setIsShown] = useState(false);

    const [ selected, setSelected ] = useState({})
    const [courses, setCourses] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [pageNumber, setPageNumber] = useState(0);


    const { fetchedCourses, loading, select } = useSelector(state => ({
        fetchedCourses: state.course.courses,
        loading: state.course.isLoading,
        select: state.course.selected,
      }))
      
    console.log('The fetched courses', fetchedCourses)
      
    //Pagination
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;

    const pageCount = Math.ceil(fetchedCourses?.total / usersPerPage);

    //End Pagination
    useEffect(() => {
        dispatch(getUser())
        dispatch(coursesEnrolled())
        dispatch(removeTitle())
        dispatch(setTitle('Available Courses'))
    }, [])

    const changePage = ({ selected }) => {
      setPageNumber(selected + 1);
    };

    useEffect(() => {
        setCourses(fetchedCourses?.courses)
    }, [fetchedCourses])
    
    useEffect(() => {
        // Simulating the API call to fetch the data

        const query = {
            'pageNumber': pageNumber,
            'searchTerm': searchValue,
        }
        dispatch(getCourses(query))
    }, [pageNumber, searchValue]);


    const handleClick = (course) => {
        setSelected(course)
        history.push(`/course-preview/${course._id}`)
        dispatch(selectCourse(course))
        // setIsShown(!isShown)
    };

    //Search

    // Debounced function to handle search submission
    const debouncedSearch = debounce((searchQuery) => {
        // Perform the actual search operation here
        console.log("Performing search with query:", searchQuery);
    }, 500); // Debounce delay set to 500 milliseconds


    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);

        // Trigger the debounced search function with the updated search value
        debouncedSearch(value);
    };

    //Preview Course

    const sizes = [1,2,3,4]

    //meta title
    document.title="Stoched";



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="mt-3">
                        <div className='d-flex justify-content-between'>
                            {/* <h2 className='my-4'>Available Courses</h2> */}

                            <form className="app-search d-none d-lg-block mb-4">
                                <div className="position-relative">
                                    <input type="text" className="form-control" placeholder="Search..." 
                                        value={searchValue}
                                        onChange={handleSearchChange}/>
                                    <button className="btn btn-primary" type="button"><i className="bx bx-search-alt align-middle"></i></button>
                                </div>
                            </form>
                        </div>
                        {loading ? (
                            <Row>
                                {sizes.map((size, i) => (
                                    <Col xl={3} lg={4} sm={6} key={i}>
                                        <Skeleton />
                                    </Col>
                                ))}
                            </Row>
                            ) : courses?.length === 0 ? (
                            <div className='my-5'>
                                <Empty
                                    img={OrdersBagImg}
                                    title="No Course Available"
                                    msg="Your courses will be displayed in this section"
                                />
                            </div>
                            ) : (
                                <>
                                     {courses?.map((course, i) => {
                                        return (
                                            <Col xl={3} sm={6} key={i} onClick={() => handleClick(course)}>
                                                <Card className='card-courses'>
                                                    <img className="card-img-top img-fluid" src={course.courseBanner} alt="" height={20} />
                                                    <CardBody className="overflow-hidden position-relative">
                                                        <div>
                                                            <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                                                        </div>
                                                        <div className="faq-count">
                                                            <h5 className="text-primary">${course.price}</h5>
                                                        </div>
                                                        <h5 className="mt-3">{course.title}</h5>
                                                        <p className="text-muted mt-3 mb-0">{truncateString(course.description, 50)}</p>
                                                    </CardBody>
                                                </Card>
                                        </Col>
                                        );
                                    })}
                                </>
                            )}
                       
                    </Row>
                    <div className='mx-0 mt-4'>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </Container>
               
            </div>

        </React.Fragment>
    );
}

export default Dashboard;