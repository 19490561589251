import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createRecord, getQuizScore } from '../../store/course/reducer';
import Empty from '../../components/CommonForBoth/Stoched/Empty';
import OrdersBagImg from "../../assets/images/orderbags.svg"


const Quiz = () => {
	const dispatch = useDispatch()

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);

	const { quiz, quizScore } = useSelector(state => ({
		quiz: state.course.quiz,
		quizScore: state.course.score,
	}))



	useEffect(() => {

		if(showScore === true){

			const percentageScore = (score / quiz.quizQuestions.length) * 100;

			const payload = {
				courseId: quiz.courseId,
				score: percentageScore + '%',
			}

			dispatch(createRecord(payload))

		}

	}, [showScore])


	const handleAnswerOptionClick = (isCorrect) => {
		if (isCorrect) {
			setScore(score + 1);
		}

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < quiz?.quizQuestions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
	} 

  return (
	<>
	{quiz.quizQuestions?.length && quiz.quizQuestions?.length ? (
			quizScore === null ? 
				<div>
					<div className='question-section'>
						<div className='question-count'>
							<span>Question {currentQuestion + 1}</span>/{quiz.quizQuestions?.length}
						</div>
						<div className='question-text font-size-20 my-3'>{quiz?.quizQuestions[currentQuestion].questionText}</div>
					</div>
					<div className='answer-section'>
						{quiz?.quizQuestions[currentQuestion].answerOptions?.map((answerOption) => (
							<button key={answerOption} onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}  className="btn btn-soft-secondary waves-effect waves-light me-1">{answerOption.answerText}</button>
						))}
					</div>
				</div>
				: 
				<div className='score-section font-size-20 mt-4 mx-3'>
					You scored {quizScore?.myScore}
				</div>
				
		): 
		<div className='my-3'>
				<Empty
					img={OrdersBagImg}
					title="No Quiz for this Course"
					msg="The author does not provide quiz for this course"
				/>
		</div>
	}
	</>
   
  )
}

export default Quiz
