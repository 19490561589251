import React from "react";
import { Col } from "reactstrap";

const Empty = (props) => {
  return (
    <Col xs={12}>
      <div className="empty-state text-center pt-5">
        <img src={props.img} alt="empty image" />
        <h5 className="mt-3">{props.title}</h5>
        <p className="text-muted small">{props.msg}</p>
      </div>
    </Col>
  );
};

export default Empty;
