import React, { useState, useRef } from 'react'
import { Row, Col, Form, Input, Label, FormFeedback} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// import { changePassword } from '../../store/actions';
import { PulseLoader } from 'react-spinners';
import { changePassword } from '../../store/auths/resetPassword/reducer';

const AuthenticationTab = () => {
    const [showHidePassword, setShowHidePassword] = useState(false);
    const [showHideNewPassword, setShowHideNewPassword] = useState(false);
    const dispatch = useDispatch();

    // const {success, error, loading } = useSelector((state) => ({
    //     success: state.CreatePassword.changeSuccessMsg,
    //     error: state.CreatePassword.changeSuccessMsg,
    //     loading: state.CreatePassword.changeLoading,
    //   }));

    const { loading } = useSelector(state => ({
        loading: state.resetPassword.isLoading,
      }))

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
          oldPassword: "",
          newPassword: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .min(8, "Password must be 8 characters long")
                .matches(/[0-9]/, "Password requires a number")
                .matches(/[^\w]/, "Password requires a symbol")
                .notOneOf([Yup.ref('oldPassword')], 'New password must be different from the old password')
                .required("Please Enter Your Password"),

            oldPassword: Yup.string().required("Please Enter Your Password"),

        }),
        onSubmit: (values) => {
    
        //   console.log("submit", values)
          dispatch(changePassword(values))

        },
      });

  return (
    <div>
        <Row className="mb-5">
            <Col xs={12} sm={12} lg={5}>
                <h6 className="profile-title pt-3 font-size-18">
                    Password
                </h6>

                <Form
                    className="needs-validation custom-form mt-4 pt-2"
                    onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                    }}
                >

                <div className="pt-3 pb-1">
                    <div className="mb-4 position-relative">
                        <Label className="form-label">Old Password</Label>
                        <div className="input-group dashpass">
                            <Input
                            name="oldPassword"
                            value={validation.values.oldPassword || ""}
                            type={showHidePassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.errors.oldPassword ? true : false
                            }
                            />
                            {validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">
                                {validation.errors.oldPassword}
                            </FormFeedback>
                            ) : null}
                            {!validation.errors.oldPassword && (
                            <span
                                className="input-group-text"
                                onClick={() =>
                                setShowHidePassword(!showHidePassword)
                                }
                            >
                                {showHidePassword ? (
                                <i className="mdi text-muted fs-4 mdi-eye"></i>
                                ) : (
                                <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                                )}
                            </span>
                            )}
                        </div>
                    </div>
                    <div className="mb-1 position-relative">
                        <Label className="form-label">New Password</Label>
                        <div className="input-group dashpass">
                            <Input
                            name="newPassword"
                            value={validation.values.newPassword || ""}
                            type={showHidePassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.errors.newPassword ? true : false
                            }
                            />
                            {validation.errors.newPassword ? (
                            <FormFeedback type="invalid">
                                {validation.errors.newPassword}
                            </FormFeedback>
                            ) : null}
                            {!validation.errors.newPassword && (
                            <span
                                className="input-group-text"
                                onClick={() =>
                                setShowHidePassword(!showHidePassword)
                                }
                            >
                                {showHidePassword ? (
                                <i className="mdi text-muted fs-4 mdi-eye"></i>
                                ) : (
                                <i className="mdi text-muted fs-4 mdi-eye-off"></i>
                                )}
                            </span>
                            )}
                        </div>
                    </div>

                    <div className="mb-4 mt-4 d-flex justify-content-end">
                        <button
                            className="btn btn-lg btn-primary px-4 d-block"
                            type="submit"
                            disabled={
                                !(validation.dirty && validation.isValid) ||
                                validation.isSubmitting
                              }
                        >
                            {loading ? <PulseLoader color="#fff" size={7} /> : "Change password"}

                        </button>
                    </div>
                </div>
            
                </Form>

                
            </Col>
        </Row>

    </div>
  )
}

export default AuthenticationTab
