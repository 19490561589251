import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isLoading: false,
  isMarking: false,
  courses: [],
  selected: [],
  selectedTitle: '',
  preview: [],
  selectedVideo: '',
  quiz: [],
  score: null,
  response: {},
  enrolled: [],
  filteredEnrolled: null, // New state for filtered courses
  markResponse: null,
  isFetching: false,
  resources: [],

};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    //Fetch Courses
    getCourses: (state) => {
      state.isLoading = true
    },
    getCoursesSuccess: (state, action) => {
      state.courses = action.payload;
      state.isLoading = false
    },
    getCoursesFailure: (state) => {
        state.isLoading = false
    },

    //Fetch A Course
    getCourse: (state) => {
      state.isLoading = true
    },
    getCourseSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false
    },
    getCourseFailure: (state) => {
        state.isLoading = false
    },
    refreshCourse: (state) => {
      state.selected = []
    },
    setTitle: (state, action) => {
      state.selectedTitle = action.payload
    },
    removeTitle: (state) => {
      state.selectedTitle = ''
    },

    fetchCourse: (state) => {
      state.loading = true
    },
    fetchCourseSuccess: (state, action) => {
      state.selected = action.payload;
      state.loading = false
    },
    fetchCourseFailure: (state) => {
        state.loading = false
    },

    //Selected Course for Preview
    selectCourse: (state, action) => {
      state.preview = action.payload
    },

    //Selected Course for Preview
    selectVideo: (state, action) => {
      state.selectedVideo = action.payload
    },

    //Fetch Quiz
    getQuiz: (state) => {
      state.isLoading = true
    },
    getQuizSuccess: (state, action) => {
      state.quiz = action.payload;
      state.isLoading = false
    },
    getQuizFailure: (state) => {
        state.isLoading = false
    },
    refreshQuiz: (state) => {
      state.quiz = []
    },


    //Fetch Quiz Score
    getQuizScore: (state) => {
      state.isLoading = true
    },
    getQuizScoreSuccess: (state, action) => {
      state.score = action.payload;
      state.isLoading = false
    },
    getQuizScoreFailure: (state) => {
        state.isLoading = false
    },

    //Course Enroll
    createEnrol: (state) => {
      state.isLoading = true
    },
    createEnrolSuccess: (state, action) => {
      state.response = action.payload;
      state.isLoading = false
    },
    createEnrolFailure: (state) => {
        state.isLoading = false
    },

    //Record Quiz
    createRecord: (state) => {
      state.isLoading = true
    },
    createRecordSuccess: (state, action) => {
      state.response = action.payload;
      state.isLoading = false
    },
    createRecordFailure: (state) => {
        state.isLoading = false
    },

    //Student Enrolled Course
    coursesEnrolled: (state) => {
      state.isLoading = true
    },
    coursesEnrolledSuccess: (state, action) => {
      state.enrolled = action.payload;
      state.isLoading = false
    },
    coursesEnrolledFailure: (state) => {
        state.isLoading = false
    },

    // New action to filter student enrolled courses by ID
    filterEnrolledCoursesById: (state, action) => {
      const courseId = action.payload;

      // Filter enrolled courses based on student ID
      state.filteredEnrolled = state.enrolled.courses?.find(
        (course) => course.course._id === courseId
      );
    },

    // Mark Course Chapter Completion
    markChapter: (state) => {
      state.isMarking = true
    },
    markChapterSuccess: (state, action) => {
      state.markResponse = action.payload;
      state.isMarking = false
    },
    markChapterFailure: (state) => {
        state.isMarking = false
    },

     //Fetch Resources
    getResources: (state) => {
      state.isFetching = true
    },
    getResourcesSuccess: (state, action) => {
      state.resources = action.payload;
      state.isFetching = false
    },
    getResourcesFailure: (state) => {
        state.isFetching = false
    },
    refreshResource: (state) => {
      state.resources = []
    },

  },
});

// Action creators are generated for each case reducer function
export const { 
  filterEnrolledCoursesById,
  coursesEnrolled, coursesEnrolledSuccess, coursesEnrolledFailure,
  createRecord, createRecordSuccess, createRecordFailure,
  createEnrol, createEnrolSuccess, createEnrolFailure,
  getQuiz, getQuizSuccess, getQuizFailure, refreshQuiz,
  getQuizScore, getQuizScoreSuccess, getQuizScoreFailure,
  selectVideo, selectCourse, 
  getCourse, getCourseSuccess, getCourseFailure, refreshCourse,
  getCourses, getCoursesSuccess, getCoursesFailure, 
  createCourse, createCourseSuccess, createCourseFailure,
  markChapter, markChapterSuccess, markChapterFailure,
  fetchCourse, fetchCourseSuccess, fetchCourseFailure,
  getResources, getResourcesSuccess, getResourcesFailure, refreshResource,
  setTitle, removeTitle,
} =
courseSlice.actions;

export const courseSelector = (state) => state.course;

export default courseSlice.reducer;
